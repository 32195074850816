<template>
    <div class="ticket_acc_his">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="container">
                <div class="row header-profile">
                    <div class="d-flex" style="border-bottom: 1px solid #C6C6C6; width: 100%;">
                        <div class="account-ticket account-ticket-1" :class="{ active: activeTab == '1' }" @click="toggleTab('1')">
                            Upcoming Ticket
                        </div>
                        <div class="account-ticket" style="margin-left: 30px;" :class="{ active: activeTab == '2' }" @click="toggleTab('2')">
                            Used Ticket History
                        </div>
                    </div>
                    <div v-show="activeTab==1" class="upcoming-ticket mt-8">
                        <div class="row" style="margin-bottom: 20px;">
                           <div class="col-lg-11 col-md-11 col-sm-11" style="background-color: #FFFFFF; border-radius: 5px;">
                                <div class="row">
                                    <div class="box-left-img" style="padding: 18px; ">
                                        <div class="box-qr">
                                            <div>
                                                <img src="@/assets/travel_package/qr.svg" alt="" style="width: 100%;">
                                            </div>
                                            <div class="in-seat d-flex align-center justify-space-between">
                                                <span>1A, Male</span>
                                                <div class="d-flex align-center">
                                                    <img src="@/assets/travel_package/arrow-right-blue.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div class="box-right-info">
                                        <div class="d-flex align-center">
                                            <div class="d-flex align-center">
                                                <img src="@/assets/travel_package/vet-bus.png" alt="">
                                            </div>
                                            <div class="ml-5 title-distination">
                                                Phnom Penh - Siem Reap
                                            </div>
                                        </div>
                                        
                                        <div class="d-flex justify-md-space-between">
                                            <div class="inf-left-box">
                                                <div class="mt-4">
                                                    Code: VET 23-000009
                                                </div>
                                                <div class="mt-4">
                                                    Booked Date: 2023-08-17 (09:30)
                                                </div>
                                                <div class="mt-4">
                                                    Telephone Number: 017577018
                                                </div>
                                                <div class="mt-4">
                                                    Boarding Point: <a href="#" style="color: #DE5D0A; font-weight: 600; text-decoration-line: underline;">View Map</a> 
                                                </div>
                                                <div class="mt-4">
                                                    Payment Type: <span style="color: #DE5D0A; font-weight: 600;"> ABA KHQR </span>
                                                </div>
                                            
                                            </div>
                                            <div class="inf-right-box">
                                                <div  class="mt-4">
                                                    Departure date: 2024-02-02 (16:30)
                                                </div>
                                                <div  class="mt-4">
                                                    Transition ID: 098765432
                                                </div>
                                                <div  class="mt-4">
                                                    Seat No: 1A, 2A
                                                </div>
                                                <div  class="mt-4">
                                                    Drop off Point: <a href="#" style="color: #DE5D0A; font-weight: 600; text-decoration-line: underline;">View Map</a> 
                                                </div>
                                                <div  class="mt-4">
                                                    <a href="#" style="color: #DE5D0A; font-weight: 600; text-decoration-line: underline;">Take a survey</a> 
                                                </div>
                                                
                                            </div>                                           
                                        </div>
                                        <div class="mt-4 row">
                                            <!-- <span style="color: #312783; " class="fs-span-luckey">* Please show your e-ticket to our staff at the ticket counter to get your physical Lucky Ticket</span> -->
                                        </div>
                                       
                                    </div>
                                </div>
                                <div style="border-top: 1px solid #C6C6C6;" class="mt-4">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 mt-3"  style="padding: 18px; position: relative;">
                                            <div style="color: #1D1D1D; font-weight: 600;">
                                                Information Customer 
                                            </div>
                                           <div class="d-flex mt-4">
                                                <div class="column-inf" style="color: #000000; font-weight: 450;">
                                                    Nationality
                                                </div>
                                                <div class="column-inf" style="color: #000000; font-weight: 450;">   
                                                    Seat No.
                                                </div>
                                                <div class="column-inf" style="color: #000000; font-weight: 450;">
                                                    Gender
                                                </div>
                                           </div>
                                           <div class="d-flex mt-4">
                                                <div class="column-inf">
                                                    Cambodian
                                                </div>
                                                <div class="column-inf">   
                                                    1A
                                                </div>
                                                <div class="column-inf">
                                                    Male
                                                </div>
                                           </div>
                                           <div class="d-flex mt-4">
                                                <div class="column-inf">
                                                    Cambodian
                                                </div>
                                                <div class="column-inf">   
                                                    2A
                                                </div>
                                                <div class="column-inf">
                                                    Female
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 mt-3"  style="padding: 18px; position: relative;">
                                            <div style="color: #1D1D1D; font-weight: 600;">
                                                Summary Payment
                                            </div>
                                            <div class="d-flex mt-4">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Grand Total
                                                </div>
                                                <div class="column-inf-right">   
                                                    $40
                                                </div>
                                               
                                            </div>
                                            <div class="d-flex mt-3">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Discount (5%)
                                                </div>
                                                <div class="column-inf-right">   
                                                    $0.50
                                                </div>
                                               
                                           </div>
                                           <div class="d-flex mt-3">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Lucky Ticket
                                                </div>
                                                <div class="column-inf-right">   
                                                    $0.25
                                                </div>
                                               
                                           </div>
                                           <div class="d-flex mt-3">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Total
                                                </div>
                                                <div class="column-inf-right">   
                                                    $32
                                                </div>
                                               
                                           </div>
                                        </div>
                                    </div>
                                </div>
                              
                           </div>
                        </div>
                        <div class="row" style="margin-bottom: 20px;">
                           <div class="col-lg-11 col-md-11 col-sm-11" style="background-color: #FFFFFF; border-radius: 5px;">
                                <div class="row">
                                    <div class="box-left-img" style="padding: 18px; ">
                                        <div class="box-qr">
                                            <div>
                                                <img src="@/assets/travel_package/qr.svg" alt="" style="width: 100%;">
                                            </div>
                                            <div class="in-seat d-flex align-center justify-space-between">
                                                <span>1A, Male</span>
                                                <div class="d-flex align-center">
                                                    <img src="@/assets/travel_package/arrow-right-blue.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div class="box-right-info">
                                        <div class="d-flex align-center">
                                            <div class="d-flex align-center">
                                                <img src="@/assets/travel_package/vet-bus.png" alt="">
                                            </div>
                                            <div class="ml-5 title-distination">
                                                Phnom Penh - Siem Reap
                                            </div>
                                        </div>
                                        
                                        <div class="d-flex justify-md-space-between">
                                            <div class="inf-left-box">
                                                <div class="mt-4">
                                                    Code: VET 23-000009
                                                </div>
                                                <div class="mt-4">
                                                    Booked Date: 2023-08-17 (09:30)
                                                </div>
                                                <div class="mt-4">
                                                    Telephone Number: 017577018
                                                </div>
                                                <div class="mt-4">
                                                    Boarding Point: <a href="#" style="color: #DE5D0A; font-weight: 600; text-decoration-line: underline;">View Map</a> 
                                                </div>
                                                <div class="mt-4">
                                                    Payment Type: <span style="color: #DE5D0A; font-weight: 600;"> ABA KHQR </span>
                                                </div>
                                            
                                            </div>
                                            <div class="inf-right-box">
                                                <div  class="mt-4">
                                                    Departure date: 2024-02-02 (16:30)
                                                </div>
                                                <div  class="mt-4">
                                                    Transition ID: 098765432
                                                </div>
                                                <div  class="mt-4">
                                                    Seat No: 1A, 2A
                                                </div>
                                                <div  class="mt-4">
                                                    Drop off Point: <a href="#" style="color: #DE5D0A; font-weight: 600; text-decoration-line: underline;">View Map</a> 
                                                </div>
                                                <div  class="mt-4">
                                                    <a href="#" style="color: #DE5D0A; font-weight: 600; text-decoration-line: underline;">Take a survey</a> 
                                                </div>
                                                
                                            </div>                                           
                                        </div>
                                        <div class="mt-4 row">
                                            <span style="color: #312783; " class="fs-span-luckey">* Please show your e-ticket to our staff at the ticket counter to get your physical Lucky Ticket</span>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div style="border-top: 1px solid #C6C6C6;" class="mt-4">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 mt-3"  style="padding: 18px; position: relative;">
                                            <div style="color: #1D1D1D; font-weight: 600;">
                                                Information Customer 
                                            </div>
                                           <div class="d-flex mt-4">
                                                <div class="column-inf" style="color: #000000; font-weight: 450;">
                                                    Nationality
                                                </div>
                                                <div class="column-inf" style="color: #000000; font-weight: 450;">   
                                                    Seat No.
                                                </div>
                                                <div class="column-inf" style="color: #000000; font-weight: 450;">
                                                    Gender
                                                </div>
                                           </div>
                                           <div class="d-flex mt-4">
                                                <div class="column-inf">
                                                    Cambodian
                                                </div>
                                                <div class="column-inf">   
                                                    1A
                                                </div>
                                                <div class="column-inf">
                                                    Male
                                                </div>
                                           </div>
                                           <div class="d-flex mt-4">
                                                <div class="column-inf">
                                                    Cambodian
                                                </div>
                                                <div class="column-inf">   
                                                    2A
                                                </div>
                                                <div class="column-inf">
                                                    Female
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 mt-3"  style="padding: 18px; position: relative;">
                                            <div style="color: #1D1D1D; font-weight: 600;">
                                                Summary Payment
                                            </div>
                                            <div class="d-flex mt-4">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Grand Total
                                                </div>
                                                <div class="column-inf-right">   
                                                    $40
                                                </div>
                                               
                                            </div>
                                            <div class="d-flex mt-3">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Discount (5%)
                                                </div>
                                                <div class="column-inf-right">   
                                                    $0.50
                                                </div>
                                               
                                           </div>
                                           <div class="d-flex mt-3">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Lucky Ticket
                                                </div>
                                                <div class="column-inf-right">   
                                                    $0.25
                                                </div>
                                               
                                           </div>
                                           <div class="d-flex mt-3">
                                                <div class="column-inf-right" style="color: #000000; font-weight: 450;">
                                                    Total
                                                </div>
                                                <div class="column-inf-right">   
                                                    $32
                                                </div>
                                               
                                           </div>
                                        </div>
                                    </div>
                                </div>
                              
                           </div>
                        </div>
                       
                    </div>
                    <div v-show="activeTab==2" class="used-ticket mt-8">
                        <div class="row" style="margin-bottom: 20px;">
                            <div class="col-lg-11 col-md-11 col-sm-11">
                                <div class="row justify-space-between">
                                    <div class="box-ticket-his"  style="background-color: #FFFFFF; border-radius: 10px; padding: 20px 20px 20px 28px; margin-bottom: 15px; ">
                                        <div class="d-flex align-center">
                                            <div class="img-src">
                                                <img src="@/assets/ticket_history/vet-img.svg" alt="">
                                            </div>
                                            <div>
                                                <span class="titile-span">Phnom Penh - Siem Reap</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/ticket-code.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">VET 23-00000009</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/calendar.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2023-08-28 (09:30)</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/truck.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">Hyundai Solati H-350</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/user.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2 Seats</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="mt-4">
                                            <!-- <span style="color: #312783;" class="note-ticket-his">* Please show your e-ticket to our staff at the ticket counter to get your physical Lucky Ticket</span> -->
                                        </div>
                                    </div>
                                    <div class="box-ticket-his"  style="background-color: #FFFFFF; border-radius: 10px; padding: 20px 20px 20px 28px; margin-bottom: 15px;">
                                        <div class="d-flex align-center">
                                            <div class="img-src">
                                                <img src="@/assets/ticket_history/vet-img.svg" alt="">
                                            </div>
                                            <div>
                                                <span class="titile-span">Phnom Penh - Siem Reap</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/ticket-code.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">VET 23-00000009</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/calendar.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2023-08-28 (09:30)</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/truck.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">Hyundai Solati H-350</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/user.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2 Seats</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="mt-4">
                                            <span style="color: #312783;" class="note-ticket-his">* Please show your e-ticket to our staff at the ticket counter to get your physical Lucky Ticket</span>
                                        </div>
                                    </div>
                                    <div class="box-ticket-his"  style="background-color: #FFFFFF; border-radius: 10px; padding: 20px 20px 20px 28px; margin-bottom: 15px;">
                                        <div class="d-flex align-center">
                                            <div class="img-src">
                                                <img src="@/assets/ticket_history/vet-img.svg" alt="">
                                            </div>
                                            <div>
                                                <span class="titile-span">Phnom Penh - Siem Reap</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/ticket-code.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">VET 23-00000009</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/calendar.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2023-08-28 (09:30)</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/truck.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">Hyundai Solati H-350</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/user.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2 Seats</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="mt-4">
                                            <span style="color: #312783;" class="note-ticket-his">*  Travel Package</span>
                                        </div>
                                    </div>
                                    <div class="box-ticket-his"  style="background-color: #FFFFFF; border-radius: 10px; padding: 20px 20px 20px 28px; margin-bottom: 15px;">
                                        <div class="d-flex align-center">
                                            <div class="img-src">
                                                <img src="@/assets/ticket_history/vet-img.svg" alt="">
                                            </div>
                                            <div>
                                                <span class="titile-span">Phnom Penh - Siem Reap</span>
                                            </div>
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/ticket-code.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">VET 23-00000009</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/calendar.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2023-08-28 (09:30)</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="d-flex align-center mt-6">
                                            <div class="d-flex align-center items" style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/truck.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">Hyundai Solati H-350</span>
                                                </div>
                                            </div>
                                            <div  class="d-flex align-center items"  style="width:50%;">
                                                <div class="img-icon">
                                                    <img src="@/assets/ticket_history/user.svg" alt="">
                                                </div>
                                                <div>
                                                    <span class="title-item">2 Seats</span>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="mt-4">
                                            <span style="color: #312783;" class="note-ticket-his">* Please show your e-ticket to our staff at the ticket counter to get your physical Lucky Ticket</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
      
  </div>
</template>
<script>

    export default{
        data(){
            return {
                activeTab: 1, 
                copyStatus: 'Copy'
            }
        },
        computed:{
            
        },
      
        methods:{
            toggleTab(tab) {
                this.activeTab = tab;
            },
            Copytitle(text){
                this.copyStatus = 'Copied!';
                const textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);
                // Select and copy the text from the textarea
                textarea.select();
                document.execCommand('copy');
                // Remove the textarea from the DOM
                setTimeout(() => {
                    this.copyStatus = 'Copy';
                }, 1000); // You can adjust the delay as needed
                document.body.removeChild(textarea);
            },
            scrollToTop() {
                window.scrollTo(0,0);
            }
            
        },
       
        created(){
          this.scrollToTop();
        }
    }
</script>
<style scoped>
  .ticket_acc_his {
      padding-top: 55px;
      /* padding-bottom: 50px; */
  }
  .header-profile{
    padding-top: 35px;
    padding-bottom: 70px;
    box-sizing: border-box;
    
  }
  .inf-account .tel{
    color: #1D1D1D !important;
    font-size: 16px;
  }
  .inf-account .number{
    color: #312783 !important;
    font-size: 16px;
  }
  .box-left-img{
    width: 28%;
  }
  .items .title-item{
    color: #1D1D1D; 
    font-weight: 600; 
    margin-left: 12px;
  }
  .box-right-info{
    width: 72%;
    padding: 18px 18px 18px 35px;
  }
  .column-inf{
    width: 33.33%;
  }
  .column-inf-right{
    width: 50%;
  }
  .upcoming-ticket{
    margin-left: 65px;
    width: 100%;
  }
  .used-ticket{
    margin-left: 65px;
    width: 100%;
  }
  .box-qr{
    background-color: #D9D9D9; 
    padding: 25px 30px 50px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .inf-left-box{
    width: 48%;
  }
  .box-ticket-his{
    width: 49%;
  }
  .box-ticket-his .titile-span{
    color: #1D1D1D; 
    font-weight: 650; 
    margin-left: 20px;
  }
  .account-ticket-1{
    margin-left: 50px;
  }
  .inf-left-box div,.inf-right-box div{
    color: #1D1D1D;
    font-size: 15px;
  }
  .inf-right-box{
    width: 48%;
  }
  .title-distination{
    color: #1D1D1D;
    font-weight: 700;
  }
  .in-seat{
    position: absolute;
    background-color: #D9D9D9; 
    left: 10%;
    bottom: 5%;
    width: 80%;
    
  }
  .in-seat span{
    color: #DE5D0A;
    font-size: 17px;
    font-weight: 650;
  }
  .account-ticket.active {
    border-bottom: 3px solid #312783;
    }
    .account-ticket:hover {
        color: #312783 !important;
    }
    .box-account{
        background: #FF0000;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 15px 7px 15px;
    }
    .account-ticket{
        cursor: pointer;
        padding: 10px;
        font-weight: bold;
        color: #000000;
    
  }
  .used-ticket .title-package{
    color: #312783;
  }
  .information-travel ul li{
    color: #6A6969;
    padding: 1px;
  }
  
  .copy-past{
    cursor: pointer;
  }
  @media screen and (width < 1265px){
    .box-left-img{
        width: 35%;
    }
    .box-right-info{
        width: 65%;
        padding: 18px 15px 18px 25px;
    }
    .inf-left-box div,.inf-right-box div{
        font-size: 13px;
    }
    .fs-span-luckey{
        font-size: 13px;
    }
  }
  @media screen and (width < 950px){
        /* .ticket_acc_his{
            margin-top:-35px;
        } */
    }
    @media screen and (width < 890px){
        /* .ticket_acc_his{
            margin-top:-35px;
        } */
        .box-left-img{
            width: 37%;
        }
        .box-right-info{
            width: 63%;
            padding: 18px 15px 18px 15px;
        }
        .inf-left-box div,.inf-right-box div{
            font-size: 12x;
        }
        .fs-span-luckey{
            font-size: 12px;
        }
        .items .title-item{
            font-size: 12px;
        }
        .note-ticket-his{
            font-size: 12px;
        }
    }
    @media screen and (width < 877px){
        .box-left-img{
            width: 30%;
        }
        .box-right-info{
            width: 70%;
            padding: 18px 15px 18px 15px;
        }
        .inf-left-box div,.inf-right-box div{
            font-size: 12px;
        }
        .fs-span-luckey{
            font-size: 12px;
        }
        .in-seat{
           
            left: 15%;
            bottom: 10%;
            width: 70%;
        }
        .items .title-item{
            font-size: 12px;
        }
    }
    @media screen and (width < 790px){
        .box-ticket-his{
            width: 100%;
        }
        .items .title-item{
            font-size: 14px;
        }
        .note-ticket-his{
            font-size: 14px;
        }
    }
    @media screen and (width < 689px){
        /* .ticket_acc_his{
            margin-top: -35px;
        } */
        .box-left-img{
            width: 100%;
        }
        .box-right-info{
            width: 100%;
            padding: 18px 15px 18px 15px;
        }
    }
    @media screen and (width < 620px){
        /* .ticket_acc_his{
            margin-top: -35px;
        } */
    }
    @media screen and (width < 600px){
        /* .ticket_acc_his{
            margin-top: -35px;
        }    */
        .upcoming-ticket{
            margin-left: 30px;
            width: 90%;
            
        }
        .used-ticket{
            margin-left: 30px;
            width: 90%;
        }
    }
    @media screen and (width < 560px){
        
    }
    @media screen and (width < 530px){
        
    }
    @media screen and (width < 500px){
        /* .ticket_acc_his{
            margin-top: -35px;
        }    */
        
    }
    @media(width < 480px) {
       
    }
    @media(width < 450px) {
        /* .ticket_acc_his{
            margin-top: -35px;
        } */
    }

    @media screen and (width < 438px){
        /* .ticket_acc_his{
            margin-top: -35px;
        } */
        .upcoming-ticket{
            margin-left: 20px;
            width: 90%;
            
        }
        .used-ticket{
            margin-left: 20px;
            width: 90%;
        }
        .account-ticket-1{
            margin-left: 30px;
        }
        .items .title-item{
            font-size: 13px;
        }
        .note-ticket-his{
            font-size: 13px;
        }
    }
    @media screen and (width < 395px){
        .items .title-item{
            font-size: 11px;
        }
        .note-ticket-his{
            font-size: 11px;
        }
    }
</style>