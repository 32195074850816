<template>
  <div class="gallers">
    <div class="containers">
      <div>
        <h2 class="title-head">VET Gallery</h2>
      </div>
      <div class="content" v-for="(gallary,i) in dataGallery" :key="i">
        <div>
          <h2 class="title">{{ gallary.name }}</h2>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 pad-lap" v-for="(boxItem, j) in gallary.box" :key="j">
              <div style="background-color: white" class="img-box-head">
                <div
                  class="img-box"
                  style="background-color: #e5e7eb"
                  @click="openMypopup(boxItem.clidenImage)"
                >
                  <img
                    :src="boxItem.image"
                    alt=""
                    width="100%"
                  />
                </div>
                <div class="title-bus" style="background-color: #e5e7eb">
                  <span>{{ boxItem.name }}</span>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <v-row
      justify="center"
      style="z-index: 20020 !important; background-color: #ffffff !important"
    >
      <v-dialog
        v-model="showPopup"
        persistent
        max-width="75%"
        style="z-index: 20020 !important; background-color: #ffffff !important"
      >
        <v-card
          style="
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: #ffffff !important;
          "
        >
          <div style="width: 96%; margin: 0 auto">
            <div class="box-close-container d-flex justify-end">
              <div class="box-close" @click="closeMyPopup">
                <span>Close</span>
              </div>
            </div>
            <div class="img-popup">
              <!-- <img src="@/assets/galleries/img-bus.png" alt="" width="100%" style=""> -->
              <vueper-slides
                fractions
                arrow="true"
                height="100%"
                class="height-img"
                style="border-radius: 6px; position: relative"
              >
                <vueper-slide
                  v-for="(slide, i) in slides"
                  :key="i"
                  :image="slide.image"
                  style="height: 100%; border-radius: 6px"
                />
                <template #arrow-left>
                  <div
                    class="arrow left-arrow d-flex justify-center align-center"
                  >
                    <img
                      src="@/assets/travel_package/left-chevron.svg"
                      alt="Left Arrow"
                      width="30px"
                    />
                  </div>
                </template>
                <template #arrow-right>
                  <div
                    class="arrow right-arrow d-flex justify-center align-center"
                  >
                    <img
                      src="@/assets/travel_package/right-chevron.svg"
                      alt="Right Arrow"
                      width="30px"
                    />
                  </div>
                </template>
              </vueper-slides>
            </div>
          </div>
          <v-spacer></v-spacer>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      showPopup: false,
      page: 1,
      slides : [],
      dataGallery: [
        {
          id: 1,
          name: 'Vireak Buntham Express Travel',
          box:[
            {
              name : "Hotel Bus",
              image: require("@/assets/galleries/vet/Hotel_Bus/Cover/Hotel_Bus_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-06.jpg"),
                },
                
              ]
            },
            {
              name : "Luxury Hotel Bus",
              image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Cover/Luxury Hotel Bus_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-07.jpg"),
                },
                
              ]
            },
            {
              name : "Luxury Coaster",
              image: require("@/assets/galleries/vet/Luxury_Coaster/Cover/Luxury Coaster_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-07.jpg"),
                },
                
              ]
            },
            {
              name : "Luxury Van-H350",
              image: require("@/assets/galleries/vet/Luxury_Van-H350/Cover/Classic Hyundai Solati H350_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-07.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-04.jpg"),
                },
                
              ]
            },
            {
              name : "Luxury Van-H350",
              image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Cover/Luxury Van-Hiace_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-06.jpg"),
                },
                
                
              ]
            },
          
          ]
          
        },
        {
          id: 2,
          name: 'VET Air Bus Express',
          box:[
            {
              name : "Air Bus",
              image: require("@/assets/galleries/vet/Air_Bus/Cover/Air Bus_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-07.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-08.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-09.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-10.jpg"),
                },
              ]
            },
            
          
          ]
          
        },
        {
          id: 3,
          name: 'Buva sea cambodia',
          box:[
            {
              name : "SpeedBoat",
              image: require("@/assets/galleries/vet/SpeedBoat/Cover/SpeedBoat_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-05.jpg"),
                },
                
              ]
            },
            
          
          ]
          
        },
       
      ],
      
     
     
    };
  },
  methods: {
    fixscrollshow() {
      const htmlElement = document.querySelector("html");
      htmlElement.classList.add("fix-scroll");
    },
    removefixscroll() {
      const htmlElement = document.querySelector("html");
      htmlElement.classList.remove("fix-scroll");
    },
    openMypopup(images) {
      this.showPopup = true;
      this.slides = images;
      this.fixscrollshow();
    },
    closeMyPopup() {
      this.showPopup = false;
      this.removefixscroll();
    },
  },
  created() {
    this.removefixscroll();
  },
};
</script>

<style scoped>
.gallers {
  margin-top: 100px;
  padding-bottom: 50px;
}

.title-head {
  color: #000000 !important;
}

.content {
  padding-top: 25px;
}
.right-arrow {
  position: absolute;
  right: 2% !important;
}
.left-arrow {
  position: absolute;
  left: 2% !important;
}
.arrow:hover {
  background-color: #fd7a23;
}
.arrow {
  font-size: 24px;
  color: white;
  background-color: #fd7a23;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.title {
  color: #de5d0a;
  padding-bottom: 20px;
}

.title-bus {
  padding: 9px 20px 10px 20px;
}
.pad-lap {
  padding-right: 0px;
}

.title-bus span {
  color: #2f354d;
  font-weight: 600;
}
.img-box {
  cursor: pointer;
}
.img-box img {
  object-fit: cover;
}
.height-img {
  height: 500px;
}

.box-close {
  width: 100px;
  color: white;
  background: #e26a6a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-bottom: 18px;
  cursor: pointer;
}
.box-close span {
  font-weight: 600;
  font-size: 16px;
}

.width-max {
  max-width: 60%;
}

.mb-img {
  margin-bottom: -5px;
}

.screen-large {
  display: block !important;
}

.screen-mobile {
  display: none !important;
}

.v-dialog__content--active {
  z-index: 1000000000 !important;
}

@media (width < 600px) {
  .pad-lap {
    padding-right: 11px !important;
  }
  .height-img {
    height: 250px;
  }
}
</style>