import { render, staticRenderFns } from "./FeedBack.vue?vue&type=template&id=46d6f266&scoped=true"
import script from "./FeedBack.vue?vue&type=script&lang=js"
export * from "./FeedBack.vue?vue&type=script&lang=js"
import style0 from "./FeedBack.vue?vue&type=style&index=0&id=46d6f266&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d6f266",
  null
  
)

export default component.exports