<template>
    <div class="travel_his">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="container">
                <div class="row header-profile">
                    <div class="d-flex" style="border-bottom: 1px solid #C6C6C6; width: 100%;">
                        <div class="account-ticket account-ticket-1" :class="{ active: activeTab == '1' }" @click="toggleTab('1')">
                            Account Ticket
                        </div>
                        <div class="account-ticket" style="margin-left: 30px;" :class="{ active: activeTab == '2' }" @click="toggleTab('2')">
                            Travel Package History
                        </div>
                    </div>
                    <div v-show="activeTab==1" class="account-ticket-history mt-8">
                        <div class="row">
                            <div class="count-ticket mr-4 mt-3">
                                <img src="@/assets/travel_package/Ellipse 15.png" alt="">
                                <div>
                                    1/10
                                </div>
                            </div>
                            <div class="inf-account d-flex flex-column justify-space-around">
                                <div>
                                <span class="tel">Tel:</span> <span class="number"> 012 345 678</span>
                                </div>
                                <div class="box-account">
                                    General Account
                                </div>
                            </div>
                        </div>
                        <div class="mt-6">
                            <div class="mb-3" style="font-weight: bold;">Condition <span style="color: #DE5D0A;;"> *</span></div>
                            <ul style="list-style-type: decimal;">
                                <li>Buy 10 tickets you will become VIP Customer.</li>
                                <li>You will get 1 ticket for free on every 11th ticket.</li>
                                <li>VIP customer get $1 discount on each ticket.</li>
                            </ul>
                        </div>
                    </div>
                    <div v-show="activeTab==2" class="travel-package-history mt-8" >
                        <div class="row">
                            <h3 class="title-package">Domestic Travel Package (Cambodia) – 96USD</h3>
                        </div>
                        <div class="row mt-10">
                            <div>
                                <img src="@/assets/travel_package/default_pf.svg" alt="" width="160px" height="160px">
                            </div>
                            <div class="information-travel" style="margin-top: 25px; margin-left: 20px;">
                                <ul>
                                    <li>TEKLY Online</li>
                                    <li>017848185</li>
                                    <li>tekly@gmail.com</li>
                                    <li>Price $240</li>

                                </ul>
                            </div>
                        </div>
                        <div class="row mt-8 inf-invoice">
                            <div class="col-lg-10 col-md-10 col-sm-10 info-package-buy d-flex align-center">
                                <div class="img-car d-flex align-center">
                                    <img src="@/assets/travel_package/truck.svg" alt="">
                                </div>
                                <div class="title-car ml-5 ">
                                    <div class="d-flex">
                                        <span>Code Travel: 000000001</span> <span class="d-flex justify-center copy-past" @click="Copytitle('000000001')">  <img src="@/assets/travel_package/copy.svg" alt="" style="margin-left: 10px; margin-right: 3px;">  {{ this.copyStatus }}</span>
                                    </div>
                                    <div> 
                                        <span>Issue Date: 2024-01-15</span>
                                    </div>
                                    <div>
                                        <span>Expired Date: 2024-12-15</span>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                        <div class="row mt-6">
                            <div class="col-10 mb-3"> 
                                <span style="font-weight: bold; color: #312783;">Term & Condition:</span> 
                                <div class="mt-2" style="color: #424245;">
                                    The content of this product is provided by machine translation and may not reflect the actual information, please take this into consideration before booking.
                                    <br>
                                    <span>- Dubai sightseeing single 30-day visa (excluding insurance) agency specifications -</span>
                                    <ul>
                                        <li>Visa type: Dubai visa.</li>
                                        <li>Visa validity: 60 days, invalid after expiration .</li>
                                        <li>Number of entries: single .</li>
                                        <li>Number of days allowed to stay: 30 days.</li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
      
  </div>
</template>
<script>

    export default{
        data(){
            return {
                activeTab: 1, 
                copyStatus: 'Copy'
            }
        },
        computed:{
            
        },
      
        methods:{
            toggleTab(tab) {
                this.activeTab = tab;
            },
            Copytitle(text){
                this.copyStatus = 'Copied!';
                const textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);

                // Select and copy the text from the textarea
                textarea.select();
                document.execCommand('copy');

                // Remove the textarea from the DOM
                setTimeout(() => {
                    this.copyStatus = 'Copy';
                }, 1000); // You can adjust the delay as needed
                document.body.removeChild(textarea);

            },
            scrollToTop() {
                window.scrollTo(0,0);
            }
            
        },
       
        created(){
          this.scrollToTop();
        }
    }
</script>
<style scoped>
  .travel_his {
      padding-top: 55px;
      /* padding-bottom: 50px; */
  }
  .header-profile{
    padding-top: 35px;
    padding-bottom: 70px;
    box-sizing: border-box;
    
  }
  .inf-account .tel{
    color: #1D1D1D !important;
    font-size: 16px;
  }
  .inf-account .number{
    color: #312783 !important;
    font-size: 16px;
  }
  .account-ticket-history{
        margin-left: 65px;
  }
  .account-ticket-1{
    margin-left: 50px;
  }
  .account-ticket-history ul li{
    padding-bottom: 4px;
  }
  .account-ticket.active {
    border-bottom: 3px solid #312783;
    }
    .account-ticket:hover {
        color: #312783 !important;
    }
    .box-account{
        background: #FF0000;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 15px 7px 15px;
    }
    .count-ticket{
        position: relative;
    }
    .count-ticket div{
        position: absolute;
        left: 32%;
        top: 35%;
    }
    .title-car div{
        padding: 2px;
    }
    
    .account-ticket{
        cursor: pointer;
        padding: 10px;
        font-weight: bold;
        color: #000000;
    
  }
  .travel-package-history{
    margin-left: 65px; 
    width: 100%;
  }
  .travel-package-history .title-package{
    color: #312783;
  }
  .information-travel ul li{
    color: #6A6969;
    padding: 1px;
  }
  .info-package-buy{
    background-color: #312783;
    color: #FFFFFF;
    padding: 12px 10px 12px 25px;
  }
  .copy-past{
    cursor: pointer;
  }

  @media screen and (width < 950px){
        /* .travel_his{
            margin-top:-35px;
        } */
    }
    @media screen and (width < 620px){
        /* .travel_his{
            margin-top: -35px;
        } */
    }
    @media screen and (width < 600px){
        /* .travel_his{
            margin-top: -35px;
        }    */
    }
    @media screen and (width < 560px){
        
    }
    @media screen and (width < 530px){
        
    }
    @media screen and (width < 500px){
        /* .travel_his{
            margin-top: -35px;
        }    */
        
    }
    @media(width < 480px) {
       
    }
    @media(width < 450px) {
        /* .travel_his{
            margin-top: -35px;
        } */
    }

    @media screen and (width < 438px){
        /* .travel_his{
            margin-top: -35px;
        } */
        .account-ticket-history{
            margin-left: 30px;
        }
        .travel-package-history{
            margin-left: 30px;
        }
        .info-package-buy{
            margin-left: -15px;
        }
       .account-ticket-1{
         margin-left: 30px;
       }
    }
    @media screen and (width < 395px){
        
    }
</style>