<template>
    <div class="explore-trend">
        <div class="containers">
            <p class="explare">{{$t('message.exporeTrendingPlace')}}</p>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 text-cen" v-for="(dataBlogScroll, i) in dataBlogScroll" :index="i" :key="i">
                    <div class="cambodia">
                        <img :src="dataBlogScroll.image" alt="">
                        <div class="text-name">{{ $t(dataBlogScroll.place) }}</div>
                        <!-- <div class="view-more" @click="onClick(dataBlogScroll)">
                            <a href="/new-blog" style="color: #ffffff;">
                                View More
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataBlogScroll : [
                {
                    image: require('@/assets/explore/1.jpg'),
                    place: 'message.cambodian',
                    title: '',
                    orderNumber: '',
                },
                {
                    image: require('@/assets/explore/2.jpg'),
                    place: 'message.thailand',
                    title: '',
                    orderNumber: '',
                },
                {
                    image: require('@/assets/explore/3.jpg'),
                    place: 'message.vietnam',
                    title: '',
                    orderNumber: '',
                },
            ]
        }
    },
    methods: {
        onClick(data){
            localStorage.setItem('blog detail', data);
        },
    }
}
</script>

<style scoped>
    .explore-trend {
        padding-top: 50px;
        padding-bottom: 50px;
        background: #F2F2F2;
    }
    .explare {
        color:#1D1D1D;
        font-weight: 700;
        font-size: 36px;
    }
    .cambodia {
        position: relative;
        width: 360px;
        height: 380px;
        border-radius: 20px;
        overflow: hidden;
    }
    .cambodia img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .text-name {
        position: absolute;
        top: 20px;
        left: 25px;
        font-weight: 600;
        font-size: 24px;
        color: #fff;
    }
    .view-more {
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        left: 25px;
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        border-bottom: 1px solid;
    }
    /* Responsive */
    @media( width < 1264px) {

        .txt-right {
            display: flex !important;
            justify-content: end;
        }
        .txt-center {
            display: flex !important;
            justify-content: center;
        }
        .cambodia{
            width: 310px !important;
            height: 310px !important;
        }
    }
    @media( width < 960px) {
        .text-cen {
            display: flex !important;
            justify-content: center;
        }
        .txt-right {
            display: flex !important;
            justify-content: center !important;
        }
    }
    @media (width < 500px) {
        .explore-trend {
            display: none;
        }
    }
  
</style>