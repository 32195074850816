<template>
    <div class="registerscreen">
        <div class="container-fluid"  style="background: #f2f2f2;">
            <div class="containers">
            <div class="row header-login">
                <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                
                </div>
                <div class="col-lg-6 col-sm-10 col-md-8 col-xs-12" style="background: #FFFFFF; border-radius: 15px;">
                    <form>
                        <h2 class="text-center acount-header">{{$t('message.registerAccount')}}</h2>
                        <p class="login-paragraph text-center">{{$t('message.pleaseEnterYourCredentialToRegister')}}</p>
                        <div class="mb-6 mt-2 emailmargin">
                            <label class="inner-block"><span style="color: #FF0000;padding-right: 6px">*</span>{{$t('message.enterFullName')}}</label>
                            <input type="text" v-model="names" :class="{ 'is-invalid': is_register && $v.names.$error }" class="form-control form-control-lg form-input-login" :placeholder="$t('message.enterFullName')"/>
                            <div v-if="is_register && !$v.names.required" class="invalid-feedback">{{$t('message.yourFullNameRequired')}}</div>
                        </div>
                        <div class="mb-6 mt-4 emailmargin">
                            <div class="row" style="width: 100%;">
                                <div class="form-left-national">
                                    <label class="inner-block"><span style="color: #FF0000;padding-right: 6px">*</span>{{$t('message.nationality')}}</label>
                                    <div class="equals_input" style="position: relative;">
                                        <i @click="selectNation()" class="fa-sharp fa-solid fa-caret-down absoluteicon"></i>
                                        <input @click="selectNation()" readonly type="text" v-model="nationality" :class="{ 'is-invalid': is_register && $v.nationality.$error }" class="form-control form-control-lg form-input-login1" :placeholder="$t('message.selectNationality')"/>
                                       
                                    </div>
                                    <div v-if="is_register && !$v.nationality.required" class="invalid-feedback">{{$t('message.yourNationalityRequired')}}</div>
                                    <div class="absolutedropdown" v-if="showListDataNation" v-click-outside="hidePopupNation">
                                        <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setNational(1)">{{$t('message.cambodian')}}</div>
                                        <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setNational(2)">{{$t('message.nonCambodian')}}</div>
                                    </div>
                                    <!-- <input type="email" class="form-control form-control-lg form-input-login" placeholder="Select nationality"/> -->
                                </div>
                                <div class="form-left-gender">
                                    <label class="inner-block"><span style="color: #FF0000; padding-right: 6px">*</span>{{$t('message.gender')}}</label>
                                    <div class="equals_input" style="position: relative;">
                                        <i  @click="selectGender()" class="fa-sharp fa-solid fa-caret-down absoluteicon"></i>
                                        <input type="text" readonly v-model="selectedOptionGender" :class="{ 'is-invalid': is_register && $v.selectedOptionGender.$error }" class="form-control form-control-lg form-input-login1" :placeholder="$t('message.gender')" @click="selectGender()"/>
                                        
                                    </div>
                                    <div v-if="is_register && !$v.selectedOptionGender.required" class="invalid-feedback">{{$t('message.yourGenderRequired')}}</div>
                                    <div class="absolutedropdown" v-if="showListDataGen" v-click-outside="hidePopupGen">
                                        <div style="margin-top: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGender(1)">Male</div>
                                        <div style="margin-top: 10px;margin-bottom: 10px;cursor: pointer;padding-left: 12px;padding-top: 5px;padding-bottom: 5px;" class="hover-color" @click="setGender(2)">Female</div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        <div class="mb-4 mt-2 emailmargin">
                            <label class="inner-block"><span style="color: #FF0000;padding-right: 6px">*</span>{{$t('message.email')}}</label>
                            <input type="email" v-model="email" :class="{ 'is-invalid': is_register && $v.email.$error }" class="form-control form-control-lg form-input-login" :placeholder="$t('message.email')"/>
                            <div v-if="is_register && !$v.email.required" class="invalid-feedback">{{$t('message.yourEmailRequire')}}</div>
                            <div v-if="msg.email" style="color: #FF0000;">{{ msg.email }}</div>
                        </div>
                        <div class="mb-4 mt-2 emailmargin" style="position: relative;">
                            <label class="inner-block"><span style="color: #FF0000;padding-right: 6px">*</span>{{$t('message.enterPassword')}}</label>
                            <input v-if="showPassword"  :class="{ 'is-invalid': is_register && $v.password.$error }" type="text"  v-model="password" class="form-control form-control-lg form-input-login" :placeholder="$t('message.enterPassword')"/>
                            <input type="password" :class="{ 'is-invalid': is_register && $v.password.$error }"  autocomplete="off" v-else class="form-control form-control-lg form-input-login" :placeholder="$t('message.enterPassword')" v-model="password"/>
                            <button type="button" class="button" @click="toggleShowPassword"><span class="icon is-small is-right">
                                    <i class="fas" :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                                    </span>
                            </button>

                            <div v-if="is_register && !$v.password.required" class="invalid-feedback">{{$t('message.yourPasswordRequired')}}</div>
                            <div v-if="!$v.password.minLength" class="invalid-feedback">{{$t('message.yourPasswordHaveDigit')}}</div>
                        </div>
                        <div class="mb-7 mt-2 emailmargin">
                            <label class="inner-block"><span style="color: #FF0000;"></span>{{$t('message.enterTelephoneNumber')}}</label>
                            <div class="number-phone-box" style="display: flex; align-items: center; width: 91%; border-radius: 6px; border: 1px solid #C6C6C6;">
                                <div style="width: 13%; height: 36px; display: flex; justify-content: center; align-items: center; border-right: 1px solid #C6C6C6;">
                                    +855
                                </div>
                                <div style="width: 82%;">
                                    <input type="tel" v-model="telephoneNumber" @input="validatePhoneNumber" class="form-control form-control-lg form-input-login form-password" :placeholder="$t('message.telephoneNumber')"/>
                                </div>
                            </div>
                            <span v-if="phoneNumberError.phone" style="color: #FF0000;">{{ phoneNumberError.phone }}</span>
                            
                            
                        </div>
                     
                        <!-- <div class="mb-3 mt-2 emailmargin forgetPasswordform">
                            <label class="inner-block"><router-link to="/forgot-password"><span style="color: #FF0000;"> Forgot Password?  </span></router-link></label>
                          
                        </div> -->
                        <div class="mb-7 mt-2 emailmargin">
                            <v-btn :disabled="disabled" style="border-radius: 4px; width:  91%; outline: none;" @click="addRegisterUser" color="#DE5D0A" height="50px"  class="text-capitalize btn-width reposo-height">
                                <span style="color: #FFF; font-size: 20px !important;"> {{ $t(titleRegister) }} </span>
                            </v-btn>
                            
                        </div>
                        <!-- <div class="mb-4 mt-2 emailmargin">
                            <p class="login-paragraph text-center" style="font-size: 16px;">Don't have account? <router-link to="/register"> <span style="color: #DE5D0A;">Register</span> </router-link></p>
                            
                        </div> -->
                    </form>
                </div>
                <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">
                    
            </div>
        </div>
        </div>
        </div>
        
    </div>
</template>
<script>
import {
      required,
      email,
      minLength,
  } from "vuelidate/lib/validators";
  import axios from 'axios';
    export default{
        data(){
            return{
                index: 0,
                showListDataGen: false,
                showListDataNation:false,
               
                maxLength: 9, // maximum allowed phone number length
                maxLengths: 10, // maximum allowed phone number length
                phoneNumberError: [],
                showPassword: false,
                msg: [],
                
                password: '',
                names:'',
                nationality:'',
                nationalityid:'',
                selectedOptionGenderid:'',
                selectedOptionGender: '',
                email:'',
                telephoneNumber: '',
                titleRegister: 'message.register',
                disabled:false,


                is_register:false,
            }
        },
        validations:{
            names: {required},
            password: {required,minLength: minLength(6)},
            nationality:{required},
            selectedOptionGender:{required},
            email:{required,email},
            
        },
        watch:{
            email(value) {
                // binding this to the data value in the email input
                this.email = value;
                this.validateEmail(value);
            },
            telephoneNumber(value) {
                this.telephoneNumber = value;
                this.validatePhoneNumber(value);
            },
        },
        methods:{
            toggleShowPassword() {
                this.showPassword = !this.showPassword;
            },
            setGender(value) {
                this.index = value;
                // localStorage.setItem('gender',  this.index);
                if(this.index == 0) {
                    this.selectedOptionGender = "Gender";
                    this.selectedOptionGenderid = 0;
                }else if(this.index == 1){
                    this.selectedOptionGender = "Male";
                    this.selectedOptionGenderid = 1;
                }else{
                    this.selectedOptionGender = "Female";
                    this.selectedOptionGenderid = 2;
                }
                
                this.showListDataGen = false;
            },
            selectGender() {
                this.showListDataGen = true;
            },
            hidePopupGen() {
                this.showListDataGen = false;
            },
            //nationality
            selectNation(){
                this.showListDataNation = true;
            },
            hidePopupNation() {
                this.showListDataNation = false;
            },
            setNational(value) {
                this.index = value;
                // localStorage.setItem('nationalityId',  this.index);
                if(this.index == 0) {
                    this.nationality = "Nationality";
                    this.nationalityid = 0;
                }else if(this.index == 1){
                    this.nationality = "Cambodian";
                    this.nationalityid = 1;
                }else{
                    this.nationality = "Non-Cambodian";
                    this.nationalityid = 2;
                }
                
                this.showListDataNation = false;
            },

            async addRegisterUser() {
              this.is_register=true,
              this.$v.$touch();
              if (this.$v.$invalid) {
                  return;
              }else{    
                    this.titleRegister = 'Loading';
                    this.disabled = true;
                    localStorage.setItem('emailUser',  this.email);
                    
                    const data = {
                        'firstName'      : this.names,
                        'nationalityId'  : parseInt(this.nationalityid),
                        'gender'         : parseInt(this.selectedOptionGenderid),
                        'email'          : this.email,
                        'telephone'      : this.telephoneNumber,
                        'password'       : this.password,
                    }
                    const url = this.$url + `user-register/register`;
                    let config = {
                        method: "POST",
                        headers: {
                            ...this.$header,
                            "Content-Type": "multipart/form-data"
                        },
                        data: data,
                        url,
                    };
                    axios(config).then((response) => {
                        if (response.status == 200) {
                            this.titleRegister = 'message.register';
                            this.disabled = false;
                            if (response.data.header.result == true && response.data.header.statusCode == 200) {
                                if(response.data.body.status == true) {
                                    let token = response.data.body.message;
                                    localStorage.setItem('tokenUser',token);
                                    this.$router.push("/comfirm-otp");
                                }else{
                                    alert(response.data.body.message);
                                }
                            }else {
                                alert("Please Input Information");
                            }
                        } 
                    }).catch((error) => {
                        console.log(error);
                    });
                    
              }
             
                // const formattedPhoneNumber = this.telephoneNumber.replace(/\s/g, '');
                // console.log(formattedPhoneNumber);
            },


            validatePhoneNumber(value) {
                const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/; // regular expression to validate phone number
                if (regex.test(value)) {
                    if (value.length < this.maxLength) {
                        this.phoneNumberError['phone'] = 'Telephone number is invalid';
                    }else if(value.length <= this.maxLength || this.telephoneNumber.length == this.maxLengths){ 
                        this.phoneNumberError['phone'] = ''; // valid phone number within length limit
                    }else {
                        this.phoneNumberError['phone'] = 'Telephone number is invalid'; // invalid phone number exceeding length limit
                    }
                } else {
                    this.phoneNumberError['phone'] = 'Telephone number is invalid'; // invalid phone number
                }
            },
           
            validateEmail(value) {
                if (/^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                    this.msg['email'] = '';
                } else{
                    this.msg['email'] = 'Email is invalid';
                }
            },
            scrollToTop() {
                window.scrollTo(0,0);
            }
        },
        created(){
            // localStorage.setItem("handleBrowser", 1);
            localStorage.setItem('handleRemamberPage',1); //1 register user 2 forgot page            
            this.scrollToTop();
        }
    }
</script>
<style scoped>
    .registerscreen {
        padding-top: 55px;
        /* padding-bottom: 50px; */
        margin-top: 28px;
      
    }
    .absoluteicon{
        position: absolute;
        top: 52%; 
        left: 200px;
        transform: translateY(-50%);

    }
    .invalid-feedback{
        color: #FF0000;
    }
    .is-invalid{
        border: 1px solid #FF0000 !important;
    }
    .button{
        margin-left: -28px;
        color: #B7B5B5;
    }
    .absolutedropdown{
        position: absolute;width: 14.5%;background: #fff;z-index: 10;border: 1px solid #6E6E73;border-radius: 5px;
    }
    
    .acount-header{
        margin-top: 15px;
        
    }
    .emailmargin{
        margin-left: 40px;
    }
    .header-login{
        padding-top: 35px;
        padding-bottom: 70px;
        box-sizing: border-box;
    }
    .login-paragraph{
        color: #6A6969;
        font-size: 13px;
    }
    label {
        font-weight: bold;
    }
    .inner-block{
        display: block;
        width: 92%;
        margin-bottom: 4px;
    }
    .form-input-login{
        /* width: 100%; */
        width: 91%;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        height: 45px;
        outline: none;
        padding-left: 12px;
    }
    .form-password{
        border: none !important;
        border-radius: none !important;
    }
    .forgetPasswordform{
        width: 91%;
        text-align: right;
    }
    .form-input-login1{
        width: 91%;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        height: 45px;
        outline: none;
        padding-left: 12px;
        cursor: pointer;
    }

    .btn-width{
        width: 91%;
        border-radius: 4px;
        background-color: #DE5D0A;
        /* z-index: 10000; */
    }

    .form-left-national{
        width: 48%;
        margin-left: 10px;
    }
    .form-left-gender{
        width: 48%;
        /* margin-right: 10px; */
    }
    .select-container {
        position: relative;
        width: 100%;
    }

    /* i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: #555;
        left: 10px;
    } */

    .fa-globe {
        color: #3498db;
    }

    .fa-venus-mars {
        color: #e74c3c;
    }
    .hideNational {
        transition: .5s;
        display: none;
    }
    .hover-color:hover {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 12px;
        color: #ffffff;
        background: #DE5D0A;
    }
  
    @media(min-width: 1740px) {
        
    }
    
    @media screen and(width < 1025px) {
        /* .registerscreen {
            margin-top: -20px;
        }
        .absoluteicon{
            left: 220px !important;
        }
        .absolutedropdown{
            width: 31% !important;
        } */
    }
    @media screen and (width < 1250px){
        /* .registerscreen {
            margin-top: -20px !important;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        .absoluteicon{
            left: 220px;
        }
        .absolutedropdown{
            width: 25%;
        }
    }
    @media screen and (width < 960px){
        /* .registerscreen {
            margin-top: -20px !important;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        .absoluteicon{
            left: 270px !important;
        }
        .absolutedropdown{
            width: 32% !important;
        }
    }
    @media screen and (width < 950px){
        /* .registerscreen {
            margin-top: -20px;
        } */
        .absoluteicon{
            left: 250px !important ;
        }
        .absolutedropdown{
            width: 31% !important;
        }
    }
    @media screen and (width < 910px){
        /* .registerscreen {
            margin-top: -20px;
        } */
        .absoluteicon{
            left: 240px !important ;
        }
        .absolutedropdown{
            width: 31% !important;
        }
    }
    @media screen and (width < 813px){
        /* .registerscreen {
            margin-top: -20px;
        } */
        .absoluteicon{
            left: 210px !important;
        }
        .absolutedropdown{
            width: 31% !important;
        }
    
    }
    @media screen and (width < 750px){
        .absoluteicon{
            left: 180px  !important;
        }
        .absolutedropdown{
            width: 30% !important;
        }
        .form-input-login1{
            width: 92% !important;
        }
    }
    @media screen and (width < 620px){
        /* .registerscreen {
            margin-top: -20px;
        } */
        /* .mobilescreencol{
            display: none !important;
        } */
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
        .absoluteicon{
            left: 340px;
        }
        .absolutedropdown{
            width: 87.5%;
        }
    }
    @media screen and (width < 700px){
        .absoluteicon{
            left: 180px  !important;
        }
        .absolutedropdown{
            width: 30% !important;
        }
        .form-input-login1{
            width: 92% !important;
        }
    }
    @media screen and (width < 642px){
        .absoluteicon{
            left: 165px  !important;
        }
        .absolutedropdown{
            width: 30% !important;
        }
        .form-input-login1{
            width: 92% !important;
        }
    }
    @media screen and (width < 600px){
        .absoluteicon{
            left: 210px  !important;
        }
        .absolutedropdown{
            width: 41.5% !important;
        }
        .form-input-login1{
            width: 97% !important;
        }
        .number-phone-box{
            width: 96% !important;
        }
    }
    @media screen and (width < 570px){
        .absoluteicon{
            left: 193px !important;
        }
        .absolutedropdown{
            width: 41% !important;
        }
        .form-input-login1{
            width: 97% !important;
        }
    }
    @media screen and (width < 530px){
        .absoluteicon{
            left: 180px !important;
        }
        .absolutedropdown{
            width: 41% !important;
        }
        .form-input-login1{
            width: 97% !important;
        }
        .number-phone-box{
            width: 96% !important;
        }
    }
   
    @media screen and (width < 500px){
        /* .registerscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
        .form-left-gender{
            width: 100% !important;
            margin-left: 10px !important;
        }
        .form-left-national{
            width: 100% !important;
            margin-bottom: 3px !important;
        }
        .form-input-login1{
            width: 100% !important;
        }
        .absoluteicon{
            left: 340px !important;
        }
        .absolutedropdown{
            width: 87.5% !important;
        }
        .number-phone-box{
            width: 96% !important;
        }
    }
    @media(width < 495px) {
        .absoluteicon{
            left: 395px !important;
        }
        .absolutedropdown{
            width: 88.5% !important;
        }
        .number-phone-box{
            width: 96% !important;
        }
    }
    @media(width < 450px) {
        /* .registerscreen {
            margin-top: -20px;
        } */
        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99% !important;
        }
        .form-left-gender{
            width: 100%;
            margin-left: 10px !important;
        }
        .form-left-national{
            width: 100% !important;
            margin-bottom: 3px !important;
        }
        .form-input-login1{
            width: 100% !important;
        }
        .absoluteicon{
            left: 340px !important;
        }
        .absolutedropdown{
            width: 87.5% !important;
        }
        .number-phone-box{
            width: 96% !important;
        }
        .number-phone-box{
            width: 96% !important;
        }
        
    }

    @media screen and (width < 438px){
        /* .registerscreen {
            margin-top: -20px !important;
        } */


        .mobilescreencol{
            display: none !important;
        }
        .header-login{
            padding-top: 20px;
            padding-bottom: 40px;
            opacity: 1;
        }
        .acount-header{
            margin-top: 10px;
        }
        .emailmargin{
            margin-left: 5px;
           
        }
        .form-input-login , .inner-block , .btn-width{
            width: 97% !important;
        }
        .forgetPasswordform{
            width: 99%;
        }
        .form-left-gender{
            width: 100% !important;
            margin-left: 10px !important;
        }
        .form-left-national{
            width: 100% !important;
            margin-bottom: 3px !important;
        }
        .form-input-login1{
            width: 100% !important;
        }
        .absoluteicon{
            left: 340px !important;
        }
        .absolutedropdown{
            width: 87.5% !important;
        }
       
    }
    @media screen and (width < 400px){
        .absoluteicon{
            left: 315px !important;
        }
        .number-phone-box{
            width: 96% !important;
        }
    }
</style>