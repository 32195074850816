<template>
  <div class="resort">
    <div class="row slide-banner">
      <div class="col-lg-12">
        <vueper-slides
          :dragging-distance="50"
          :arrows="false"
          class="reson-height"
          
        >
          <vueper-slide class="reson-height" style="border-radius:6px;" v-for="(dataResortBanner, i) in dataResortBanner" :key="i" :image="dataResortBanner.image" :title="dataResortBanner.title" /> 
        </vueper-slides>
      </div>
    </div>
    <div class="containers">
     
      <div
        class="row"
        style="padding-left: 12px; padding-right: 12px; padding-top: 12px"
        v-for="(dataResort, i) in dataResorts" :key="i"
      >
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row store-all">
            <div class="col-sm-5">
              <div class="img">
                <img
                  v-if="dataResort.image"
                  :src="dataResort.image"
                />
                <img
                  v-else
                  src="@/assets/placeholder-image.jpeg"
                />
              </div>
            </div>
            <div class="col-sm-7">
              <div class="titles">
                <span>
                  <a style="color: #312783" v-if="dataResort.linkWeb" :href="dataResort.linkWeb" target="_blank">{{ $t(dataResort.title) }}</a>
                  <a style="color: #312783" v-else>{{ $t(dataResort.title) }}</a>
                </span>
              </div>
              <div>
                <a v-if="dataResort.linkfb" :href="dataResort.linkfb" target="_blank" >
                  <img style="cursor: pointer" :src="dataResort.iconFacebook" alt="" />
                </a>
                <a v-else >
                  <img style="cursor: pointer" :src="dataResort.iconFacebook" alt="" />
                </a>
                <a v-if="dataResort.linkte" :href="dataResort.linkte" target="_blank">
                  <img style="cursor: pointer; margin-left: 12px" :src="dataResort.iconTelegram" alt="" />
                </a>
                <a v-else>
                  <img style="cursor: pointer; margin-left: 12px" :src="dataResort.iconTelegram" alt="" />
                </a>
              </div>
              <p style="line-height: 1.5; padding-top: 14px" class="text-font-size">
                {{ $t(dataResort.description) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      dataResortBanner: [
        {
          image: require('@/assets/resort/cover.png'),
          title: 'Our Resorts' 
        },
        
        // {
        //   image: require('@/assets/placeholder-image.jpeg'),
        // },
        // {
        //   image: require('@/assets/placeholder-image.jpeg'),
        // },
      ],
      dataResorts: [
        {
          image: require('@/assets/resort/06-Tube-Resort.jpg'),
          title: 'message.tubeResort',
          description: 'message.desTubeResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/TubeResort/",
          linkte: "https://t.me/tubebeachreosrt",
          linkWeb: "https://tuberesort.com/",
        },
        {
          image: require('@/assets/resort/07-Sandy-Beach-Resort.jpg'),
          title: 'message.sandyBeachBungalows',
          description: 'message.desSandyBeachBungalows',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/SandayBeachResort/",
          linkte: "",
          linkWeb: "https://sandybeachbungalows.com",
        },
        {
          image: require('@/assets/resort/03-Koh-Sdach-Resort.jpg'),
          title: 'message.kohSdachResort',
          description: 'message.desKohSdachResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/kohsdechresort/",
          linkte: "https://t.me/KohSdechResort",
          linkWeb: "https://kohsdachislandresort.com",
        },
        {
          image: require('@/assets/resort/08-Eden-Beach-Resort.jpg'),
          title: 'message.edenBeachResort',
          description: 'message.desEdenBeachResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/profile.php?id=100028389535319",
          linkte: "https://t.me/EdenBeachResort",
          linkWeb: "https://edenbeachresorts.com",
        },
        {
          image: require('@/assets/resort/05-Bodega-Resort.jpg'),
          title: 'message.bodegaResort',
          description: 'message.desBodegaResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/BodegaResort/",
          linkte: "https://t.me/Bodegabookings",
          linkWeb: "https://bodegabeachclub.com",
        },
        {
          image: require('@/assets/resort/04-Pidoma-Resort.jpg'),
          title: 'message.pidomaResort',
          description: 'message.desPidomaResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/pidomaresort/",
          linkte: "https://t.me/Pidomaresort",
          linkWeb: "https://pidomaresort.com",
        },
        {
          image: require('@/assets/resort/02-Romdoul-Kirirom-Resort.jpg'),
          title: 'message.romdoulKiriromResort',
          description: 'message.desRomdoulKiriromResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/profile.php?id=100091229344637",
          linkte: "https://t.me/RomdoulKiriromResort",
          linkWeb: "",
        },
        {
          image: require('@/assets/resort/10-Koh-Apikjun-Resort.jpg'),
          title: 'message.kohApikjunResort',
          description: 'message.desKohApikjunResort',
          iconFacebook: require("@/assets/icon-social-media/facebook.svg"),
          iconTelegram: require("@/assets/icon-social-media/telegram.svg"),
          linkfb: "https://www.facebook.com/KohApikjunResort",
          linkte: "https://t.me/KohApikjunResort",
          linkWeb: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.resort {
  margin-top: 50px;
  padding-bottom: 50px;
}
.mar-top {
  margin-top: 30px;
}
.store-all {
  background: #f6f7f8;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
}
.img {
  width: 100%;
  height: 303px;
  object-fit: cover;
}

.slide-banner{
  margin-top: -60px;
}

.img img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.titles {
  width: 100%;
  padding-bottom: 10px;
  color: #312783;
  font-weight: 600;
  font-size: 20px;
}
.titles span:hover {
  text-decoration: underline;
}
.see-more {
  border: 1px solid #312783;
  width: 190px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
}
.reson-height {
  height: 400px;
}
@media (width < 600px) {
  .slide-banner{
    width: 92%;
    margin: 0px auto;
  }
}
@media (width < 500px) {
  .img {
    width: 100%;
    height: 100%;
  }
  .slide-banner{
    width: 92%;
    margin: 0px auto;
  }
}
@media (width < 450px) {
  .reson-height {
    height: 220px !important;
  }
  .text-font-size {
    font-size: 14px;
  }
}
@media (width < 351px) {
  .titles span {
    font-size: 16px;
  }
  .titles span img {
    width: 20px;
    height: 20px;
  }
}
</style>
