<template>
    <div class="top-cambodia">
        <div class="containers set-het">
            <p class="top_cam">{{$t('message.localRoute')}}</p>
            <div class="row" style="padding-bottom: 20px;">
                <div class="col-lg-3 col-md-4 col-sm-4" v-for="data in infoLocalRoute" :key="data.id">
                    <span class="p-hover" @click="listSchdule(data.destinationFrom, data.destinationTo, data.destinationFromName, data.destinationToName)">{{data.description}}</span>
                </div>
            </div>
            <p class="top_cam">{{$t('message.internationalRoute')}}</p>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-4" v-for="data in infoInternationalRoute" :key="data.id">
                    <span class="p-hover" @click="listSchdule(data.destinationFrom, data.destinationTo, data.destinationFromName, data.destinationToName)">{{data.description}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            infoLocalRoute: [],
            infoInternationalRoute: [],
        }
    },
    methods: {
        async getDataLocalRoute() {
            const url = this.$url + `schedule/localRoute`;
            let config = {
                method: "POST",
                headers: this.$header,
                url,
            };
            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.infoLocalRoute = response.data.body;
                    }
                } 
            }).catch((error) => {
                console.log(error);
            });
        },
        async getDataInternationalRoute() {
            const url = this.$url + `schedule/internationalRoute`;
            let config = {
                method: "POST",
                headers: this.$header,
                url,
            };
            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.infoInternationalRoute = response.data.body;
                    }
                } 
            }).catch((error) => {
                console.log(error);
            });
        },
        listSchdule(destinationFrom, destinationTo, destinationFromName, destinationToName){
            localStorage.removeItem("handleBrowser");
            localStorage.setItem('departureFromId', destinationFrom);
            localStorage.setItem('departureFromName', destinationFromName);
            localStorage.setItem('destinationToId', destinationTo);  
            localStorage.setItem('destinationToName', destinationToName); 
            this.$router.push("/bus-list"); 
        }
    },
    created() {
        this.getDataLocalRoute();
        this.getDataInternationalRoute();
    }
}
</script>

<style>
    .top-cambodia {
        padding-bottom: 50px;
        background: #F2F2F2;
    }
    .set-het {
        height: 100%;
    }
    .top_cam {
        font-size: 20px;
        font-weight: 700;
    }
 
    .p-hover:hover {
        cursor: pointer;
        color: #312783;
        text-decoration: underline;
    }

    @media(width < 440px) {
        p{
            font-size: 10px;
        }
        .top_cam {
            font-size: 15px;
        }
    }
    @media(width < 330px) {
        p{
            font-size: 8px;
        }
        .top_cam {
            font-size: 15px;
        }
    }
</style>