<template>
    <div class="shedule-back">
        <div class="header-menu">
            <HeaderComponent />
        </div>
        <div class="desktop">
            <ListScheduleBack />
        </div>
        <div class="mobile-app">
            <MoblieScreenBack />
        </div>
        <IncFooter />
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import ListScheduleBack from '@/components/Shedule/ListScheduleBack.vue';
import MoblieScreenBack from '@/components/moblie/moblieScreenBack.vue';
export default {
  components: {HeaderComponent, IncFooter, ListScheduleBack, MoblieScreenBack },
    
    data() {
        return {
        
        }
    },
    methods: {},     
}
</script>

<style scoped> 
    .mobile-app {
        display: none;
    }
    @media(width < 960px) {
        .desktop {
            display: none;
        }
        .mobile-app {
            display: block;
        }
    }
</style>