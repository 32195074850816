<template>
    <div v-if="noResult" class="norecord">

    </div>
    <div v-else class="apply-bg">
        <div  class="bg-flash">
            <div class="containers">
                <p class="flash-sale">{{$t('message.flashSale')}}<span><img src="@/assets/icon_bars/maskgroup.svg" alt=""></span></p>
                <v-slide-group style="margin-top: -8px;">
                    <template v-slot:next>
                        <div class="arrow_left" @click="showArrow" v-if="showImgArrRight">
                            <img src="@/assets/arrows/right-arrow.svg" alt="">
                        </div>
                    </template>
                    <template v-slot:prev>
                        <div class="arrow_right" @click="showHide" v-if="ShowImgArrLeft">
                            <img src="@/assets/arrows/left-arrow.svg" alt="">
                        </div>
                    </template>
                
                    <v-slide-item v-for="(flashSale ,index) in flashSales" :key="index" v-slot="{ active, toggle }">
                        <v-card class="pad-in" outlined :input-value="active" @click="toggle,bookSeat(flashSale.destinationFrom, flashSale.destinationFromName, flashSale.destinationTo, flashSale.destinationToName, flashSale.journeyId)">
                            <div class="flex-header">
                                <span class="imgs" style="padding-right: 10px;">
                                    <img v-if="flashSale.scheduleType == 1" src="@/assets/vet.png" alt="">
                                    <img v-if="flashSale.scheduleType == 3" src="@/assets/air-bus-logo.svg" alt="">
                                </span>
                                <span class="set-font">{{flashSale.description}}</span>
                            </div>
                            <div class="water" v-if="flashSale.promotionPrice < flashSale.price">
                                <div class="imgs" style="padding-right: 17px;">
                                    <img src="@/assets/icon_bars/cambodia.svg" alt="">
                                </div>
                                <div class="price">
                                    <p>$ {{flashSale.promotionPrice}}</p>
                                </div>
                                <div class="discount">
                                    <p> {{ flashSale.promotionPrice > flashSale.price ? '' : '$' + flashSale.price}}</p>
                                </div>
                            </div>
                            <div class="amnity" v-if="flashSale.promotionPriceForeigner < flashSale.priceForeigner">
                                <div class="imgs" style="padding-right: 17px;">
                                    <img src="@/assets/icon_bars/world.svg" alt="">
                                </div>
                                <div class="price">
                                    <p>$ {{ flashSale.promotionPriceForeigner}}</p>
                                </div>
                                <div class="discount">
                                    <p> {{ (flashSale.promotionPriceForeigner > flashSale.priceForeigner) ?  '' :'$' + flashSale.priceForeigner }}</p>
                                </div>
                            </div>
                            <div class="customBtn" @click="bookSeat(flashSale.destinationFrom, flashSale.destinationFromName, flashSale.destinationTo, flashSale.destinationToName, flashSale.journeyId)">
                                {{$t('message.bookNow')}}
                            </div>
                            <div style="color: #424245;font-size: 12px;font-weight: 400;padding-top: 8px;">{{$t('message.applyPeriod')}}: {{ flashSale.applyPeriod }}</div>
                        </v-card> 
                    </v-slide-item>
                </v-slide-group>
            </div>
        </div>
    </div>
   
</template>

<script>
import axios from 'axios';
import qs from 'qs';
export default {
    data() {
        return {
            showImgArrRight: true,
            ShowImgArrLeft: false,
            flashSales: [],
            noResult: true,
        }
    },
    methods: {
        showArrow() {
            this.ShowImgArrLeft = true;
            this.showImgArrRight = true;
        },
        showHide() {
            this.ShowImgArrLeft = true;
            this.showImgArrRight = true;
        },
        async getFlashSale() {
            const data = {
                type: 1
                // 'page': 1,
                // 'rowsPerPage': 200,
                // 'total': 0,
            }
            const url = this.$url + `promotion/list`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            axios(config).then((response) => {
                // console.log(response);
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.flashSales = response.data.body;
                        // console.log(response.data.body.length);
                        if(response.data.body.length == 0){
                            this.noResult = true;
                        }else{
                            this.noResult = false;
                        }
                    }
                    }
            }).catch((error) => {
                console.log(error);
            });
        },
        bookSeat(destinationFrom, destinationFromName, destinationTo, destinationToName, journeyId) {
            localStorage.removeItem("handleBrowser");
            localStorage.setItem('journeyid', journeyId);
            localStorage.setItem('departureFromId', destinationFrom);
            localStorage.setItem('departureFromName', destinationFromName);
            localStorage.setItem('destinationToId', destinationTo);  
            localStorage.setItem('destinationToName', destinationToName);
            localStorage.setItem('flashSale', 1);
            localStorage.setItem("book-again", 1);
            this.$router.push("/bus-list");
        },
    },
    created() {
        setTimeout(() => {
            this.getFlashSale();
        }, 1000);
    }
}
</script>

<style scoped>
    .apply-bg{
        padding-bottom: 40px;
        padding-top: 68px;
        background: #F2F2F2;
    }
    .bg-flash {
        position: relative;
        background: #FDF1EA;
        padding-top: 3px;
        padding-bottom: 20px;
        
         /* margin-bottom: 28px;    */

    }
    .flash-sale {
        font-weight: 600;
        font-size: 32px;
        color: #312783;
    }
    .flash-sale img {
        width: 25px;
    }
    .norecord{
        padding-bottom: 100px;
        background: #F2F2F2;
    }
    .pa-t-0 {
        padding-top: 0;
    }
    .pad-in {
        position: relative;
        border-radius: 12px;
        padding: 10px;
        padding: 15px;
        margin-right: 10px;
    }
    .flex-header {
        display: flex;
        height: 35px;
    }
    .flex-header .imgs {
        height: 100%;
    }
    .flex-header img {
        width: 32px;
    }
    .flex-header span {
        height: 100%;
        line-height: 30px;
        font-weight: 600;
        font-size: 14px;
        color: #1D1D1D;
    } 
    
    .water {
        display: flex;
        height: 35px;
    }
    .water .imgs{
        height: 100%;
        padding-top: 2px;
    }
    .water img {
        width: 25px;
    }
    .water .price{
        height: 100%;
        line-height: 30px;
    }
    .water .price p {
        color: #DE5D0A;
        font-size: 16px;
        font-weight: 700;
    }
    .water .discount{
        padding-left: 10px;
        padding-top: 1.60px;
        width: 58%;
        height: 100%;
        line-height: 30px;
        color: #BCBCBD;
        font-weight: 500;
        -webkit-text-decoration-line: line-through; /* Safari */
        text-decoration-line: line-through; 
    }
    .water .discount p {
        color: #BCBCBD;
        font-size: 14px;
        font-weight: 400;
    } 
    .amnity {
        display: flex;
        height: 28px;
    }
    .amnity .imgs{
        height: 100%;
    }
    .amnity img {
        width: 25px;
    }
    .amnity .price{
        height: 100%;
        line-height: 30px;
    }
    .amnity .price p {
        color: #DE5D0A;
        font-size: 16px;
        font-weight: 700;
    }
    .amnity .discount{
        padding-left: 10px;
        padding-top: 1px;
        width: 58%;
        height: 100%;
        line-height: 30px;
        color: #BCBCBD;
        -webkit-text-decoration-line: line-through; /* Safari */
        text-decoration-line: line-through; 
    }
    .amnity .discount p {
        color: #BCBCBD;
        font-size: 14px;
        font-weight: 400;
    } 
    .customBtn {
        cursor: pointer;
        position: absolute;
        bottom: 45px;
        right: 10px;
        font-size: 12px;
        border-radius: 5px !important;
        padding: 7px 7px;
        font-weight: 600;
        color: #DE5D0A;
        border: 1px solid #DE5D0A;
    }
    /* Responsive */
    @media (width < 1169px) {
        .res {
            flex: 0 0 50%;
            width: 50%;
            max-width: 50%;
        }
    }
    @media (width < 950px) {
        .norecord{
            padding-bottom: 100px !important;
        }
        .apply-bg {
            padding-top: 65px;
            padding-bottom: 35px;
        }
    }
    @media (width < 621px) {
        .res {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
        }
    }
    @media (width < 500px) {
        .apply-bg {
            padding-bottom: 35px;
            padding-top: 65px;
        }
        .flash-sale {
            font-size: 20px;
        }
        .flash-sale img {
            width: 17px;
        }
    }
</style>