<template>
  <div class="gallers">
    <div class="containers">
      <div class="title-gallers">{{$t('message.galleryVetExpress')}}</div>
      <div class="name-gallers">{{ $t(nameBus) }}</div>
      <div class="row" style="margin-top: 14px;">
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-xs-12 pa-t-b"
          v-for="(dataGallerImage, index) in parsedDataGallerImages" :key="index"
        >
          <div class="galler_bus">
            <img :src="dataGallerImage.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  components: {},
  data() {
    return {
      nameBus: this.$route.query.name,
      dataGallerImages: this.$route.query.clidenImage,
      parsedDataGallerImages: [],
    };
  },
  methods: {
   
  },
  mounted() {
    this.parsedDataGallerImages = JSON.parse(this.dataGallerImages);
  },
  computed: {},
  
};
</script>
  
  <style scoped>
.gallers {
  margin-top: 130px;
  padding-bottom: 50px;
}
.title-gallers {
  color: #de5d0a;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 6px;
}
.name-gallers {
  color: #1d1d1d;
  text-align: center;
  font-weight: 600;
  font-size: 36px;
}
.galler_bus {
  width: 100%;
}
.galler_bus img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.galler_bus p {
  font-weight: 600;
  font-size: 20px;
  color: #1d1d1d;
}
</style>