<template>
    <header :class="{ 'onScroll': !narbarTop.topOfPage, 'header': narbarTop.topOfPage }">
        <div class="nav containers">
            <h2 class="nav_logo">
                <router-link to="/" style="border-bottom: none !important;"><img src="@/assets/vet.png" alt="" width="50" @click="scrollUp"></router-link> 
                <!-- <router-link to="/" class="noneImgScreen" v-if="narbarTop.topOfPage"><img src="@/assets/vet.png" alt="" width="50" @click="scrollUp"></router-link> -->
            </h2>
            <!-- <h2 class="nav_logo noneImgScreen"><router-link to="/"><img src="@/assets/vet.png" alt="" width="50" @click="scrollUp"></router-link></h2> -->
            <ul class="menu_items" :class="isMobileNavigationVisible === false ? 'menu_items': 'menu_itemes'">
                <img class="hidden" src="@/assets/vet.png" alt="" width="50">
                <i class="fa-solid fa-xmark" id="menu_toggle" @click="nav_toggle"></i>
                <li class="pad-top home" @click="scrollUp">
                    <router-link to="/" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.home')}}</router-link>
                </li>
                <li class="pad-top" @click="allPage()">
                    <router-link to="/company-profile" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.aboutUs')}}</router-link>
                </li>
                <!-- <li class="pad-top">
                    <a href="https://www.buvasea.com/" target="_blank" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.speedBoat')}}</a>
                </li> -->
                <!-- <li class="pad-top" @click="allPage()">
                    <router-link to="/Restore" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.resort')}}</router-link>
                </li> -->
                <li class="pad-top" @click="allPage()">
                    <router-link to="/retal" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.rentalService')}}</router-link>
                </li>
                <!-- <li class="pad-top" @click="allPage()">
                    <router-link to="/travel-package" style="color: #000;font-weight: 600" class="nav_link">Travel Package</router-link>
                </li> -->
                <li class="pad-top" @click="allPage()">
                    <router-link to="/Galleries" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.gallery')}}</router-link>
                </li>
                <li class="pad-top" @click="allPage()">
                    <router-link to="/blog" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.blogs')}}</router-link>
                </li>
                <li class="pad-top" @click="scrollDown()">
                    <span style="color: #000;font-weight: 600" id="contact" class="nav_link">{{$t('message.contact')}}</span>
                </li>
                <!-- <li class="pad-top mobileScreenli" @click="allPage()">
                    <router-link to="/login" style="color: #000;font-weight: 600" class="nav_link">{{$t('message.loginRegister')}}</router-link>
                </li>  -->
                <li class="pad-top destopApp" @click="toggleLanguagePopup" style="position: relative;" title="Switch Language">
                    <span style="color: #000;font-weight: 600; display: flex; align-items: center;" class="nav_link"> 
                       {{ this.lange === "ch" ? "中文" : this.lange === "kh" ? "ភាសាខ្មែរ" : "English" }}  &nbsp; <img src="@/assets/flag/caret-down-outline.svg" alt="" style="margin-top: 3px;"></span>

                      <!-- Language Popup -->
                    <div v-if="showLanguagePopup" class="language-popup">
                        <!-- <div :class="{'color-highlight': this.lange == 'kh' , 'disabled': this.lange == 'kh'}" title="Khmer" @click="selectLanguage('kh')" class="d-flex align-center"> <img src="@/assets/flag/kh.svg" width="24px" height="24px" alt="" class="mr-2"> ភាសាខ្មែរ</div> -->
                        <div title="English" @click="selectLanguage('en')"   :class="{'color-highlight': this.lange == 'en' , 'disabled': this.lange == 'en'}" class="d-flex align-center">English</div>
                        <div title="China" @click="selectLanguage('ch')" :class="{'color-highlight': this.lange == 'ch' , 'disabled': this.lange == 'ch'}" class="d-flex align-center">中文</div>
                    </div>
                </li>
                               <!-- Language on small screen-->
                <li class="pad-top mobileScreenli" style="cursor: pointer;">
                    <div class="smallOnlymobile" style="display: flex; justify-content: space-between; align-items: center;" title="Switch Language" @click="toggleLanguagePopup()">
                        <div>
                            <span style="color: #000;font-weight: 600" class="nav_link">Language</span>
                        </div>
                        <div>
                            <img src="@/assets/arrows/vector-bottom.svg" alt="">
                        </div>
                    </div>
                    <transition name="slide">
                        <div class="language" v-if="showLanguagePopup">
                            <ul class="mobileul">
                                <!-- <li @click="selectLanguage('kh')"  class="pad-top d-flex justify-space-between align-center" style="color: #1D1D1D !important;" title="Khmer"> <div title="Khmer" @click="selectLanguage('kh')" class="d-flex align-center" style="margin-left: -16px;"> <img src="@/assets/flag/kh.svg" width="24px" height="24px" alt="" class="mr-2"> ភាសាខ្មែរ</div> <div v-if="this.lange=='kh'" style="margin-right: -16px;"><img src="@/assets/arrows/check.svg" width="15px" height="15px" alt=""></div></li> -->
                                <li @click="selectLanguage('en')" class="pad-top d-flex justify-space-between align-center" style="color: #1D1D1D !important;" title="English"><div  title="English" @click="selectLanguage('en')" class="d-flex align-center" style="margin-left: -16px;"> <img src="@/assets/flag/gb.svg" width="24px" height="24px" alt="" class="mr-2"> English </div> <div v-if="this.lange=='en'"  style="margin-right: -16px;"><img src="@/assets/arrows/check.svg" width="15px" height="15px" alt=""></div></li>
                                <li @click="selectLanguage('ch')" class="pad-top d-flex justify-space-between align-center" style="color: #1D1D1D !important;" title="China"><div  title="China" @click="selectLanguage('ch')" class="d-flex align-center" style="margin-left: -16px;"><img src="@/assets/flag/cn.svg" width="24px" height="24px" alt="" class="mr-2">中文</div> <div v-if="this.lange=='ch'"  style="margin-right: -16px;"><img src="@/assets/arrows/check.svg" width="15px" height="15px" alt=""></div></li>
                            </ul>
                        </div>
                    </transition>
                </li>
                <!-- <li class="pad-top destopApp" @click="allPage()">
                    <router-link to="/login" style="color: #FFFFFF;font-weight: 600" class="nav_link sign-in-acc">Sign in Account</router-link>
                </li> -->
                <!-- User account on small screen-->
                <!-- <li class="pad-top mobileScreenli" style="cursor: pointer;">
                    <div class="smallOnlymobile" style="display: flex; justify-content: space-between; align-items: center;" @click="toggleLanguagePopup()">
                        <div>
                            <router-link to="#" style="color: #000;font-weight: 600" class="nav_link">User Account</router-link>
                        </div>
                        <div>
                            <img  src="@/assets/arrows/vector-bottom.svg" alt="">
                        </div>
                    </div>
                    <transition name="slide">
                        <div class="useraccount" v-if="showLanguagePopup">
                            <ul class="mobileul">
                                <li @click="selectLanguage('kh')"  class="pad-top d-flex justify-space-between align-center" style="color: #1D1D1D !important;" title="Khmer"> <div title="Khmer" @click="selectLanguage('kh')" class="d-flex align-center" style="margin-left: -16px;"> <img src="@/assets/flag/kh.svg" width="24px" height="24px" alt="" class="mr-2"> ខ្មែរ</div> <div v-if="this.lange=='kh'" style="margin-right: -16px;"><img src="@/assets/arrows/check.svg" width="15px" height="15px" alt=""></div></li>
                                <li @click="selectLanguage('en')" class="pad-top d-flex justify-space-between align-center" style="color: #1D1D1D !important;" title="English"><div  title="English" @click="selectLanguage('en')" class="d-flex align-center" style="margin-left: -16px;"> <img src="@/assets/flag/gb.svg" width="24px" height="24px" alt="" class="mr-2"> English </div> <div v-if="this.lange=='en'"  style="margin-right: -16px;"><img src="@/assets/arrows/check.svg" width="15px" height="15px" alt=""></div></li>
                                <li @click="selectLanguage('ch')" class="pad-top d-flex justify-space-between align-center" style="color: #1D1D1D !important;" title="China"><div  title="China" @click="selectLanguage('ch')" class="d-flex align-center" style="margin-left: -16px;"><img src="@/assets/flag/cn.svg" width="24px" height="24px" alt="" class="mr-2">中文</div> <div v-if="this.lange=='ch'"  style="margin-right: -16px;"><img src="@/assets/arrows/check.svg" width="15px" height="15px" alt=""></div></li>
                            </ul>
                        </div>
                    </transition>
                </li> -->
            </ul>
            <i class="fa-solid fa-bars" id="menu_toggle" @click="nav_toggle"></i>
        </div>
    </header>
</template>


<script>
    export default {
        data() {
            return {
                isMobileNavigationVisible: false,
                narbarTop: {
                    topOfPage: true
                },
                showLanguagePopup: false,
                showUserPopup:false,
                lange:  this.$i18n.locale,
            }
        },
        methods: {
            allPage() {
                localStorage.removeItem("handleBrowser");
            },
            toggleLanguagePopup() {
                this.showLanguagePopup = !this.showLanguagePopup;
                this.showUserPopup = false;
                
            },
            toggleUserPopup() {
                this.showUserPopup = !this.showUserPopup;
                this.showLanguagePopup = false;
                
            },
            RouterPage(value){
                if(value=="Profile"){
                    this.$nextTick(() => {
                        this.$router.push("/profile-account");
                    });
                }else if(value == "Travel"){
                    this.$router.push("/company-profile");
                }else if(value == "Ticket"){
                    this.$router.push("/retal");
                }else if(value == "Log"){
                    this.$router.push("/logout");
                }
            },

            selectLanguage(locale) {
                this.$i18n.locale = locale;
                this.lange = locale;
                this.showLanguagePopup = false;
                localStorage.setItem("lang",this.$i18n.locale);
                if (this.$route.path == "/" || this.$route.path == "/passenger-info") {
                    window.location.reload();
                }
                
                localStorage.setItem('adspopup','1');

                this.toggleLanguagePopup();
                // console.log(this.lange);
                this.isMobileNavigationVisible = false;
            },
            nav_toggle() {
                if(this.isMobileNavigationVisible == false) {
                    this.isMobileNavigationVisible = true;
                }else{
                    this.isMobileNavigationVisible = false;
                }
            },
            scrollDown() {
                window.scrollTo(0, 3000);
                // active and unactive 
                var span = document.getElementById("contact");
                span.classList.add("router-link-exact-active");
                var menuItems = document.querySelectorAll("li a");
                menuItems.forEach(function(item) {
                    item.classList.remove("router-link-exact-active");
                });
                this.nav_toggle();
            },
            scrollUp() {
                if (this.$route.path !== "/") {
                    this.$router.go();
                    this.$router.push("/");
                    
                }
                // this.$router.go();
                // this.$router.push("/");
            },
            handleScroll() {
                if(window.pageYOffset>0){
                    if(this.narbarTop.topOfPage) this.narbarTop.topOfPage = false
                } else {
                    if(!this.narbarTop.topOfPage) this.narbarTop.topOfPage = true
                }
            },
        },
        created(){
            // console.log(this.lange);
            if(localStorage.getItem('adspopup') != 1){
                this.$i18n.locale = 'en';
                this.lange = this.$i18n.locale;
                localStorage.setItem('lang',this.$i18n.locale);
            }
        },
        beforeMount() {
            window.addEventListener('scroll', this.handleScroll)
        },
    }
</script>

<style>
   /* Start Header */
    header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
    }
    .header{
        /* padding-bottom:20px; */
        background-color: #FFFFFF !important;
        opacity: 1;
       
    }
    .noneScroll{
        display: none !important;
    }
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .nav_logo {
        padding: 10px 0;
    }
    .mobileScreenli{
        display: none;
    }
    .menu_items {
        display: flex;
        align-items: center;
        list-style: none;
        gap: 30px;
        /* margin-top: 18px; */
    }
    .color-highlight{
        color: #DE5D0A !important; 
    }
    .disabled {
        pointer-events: none;
    }
    .sign-in-acc{
        background: #DE5D0A;
        padding: 10px 18px 10px 18px;
        border-radius: 12px;
    }
    .sign-in-acc:hover{
        color: #FFFFFF !important;
        opacity: 1;
    }
    li .sign-in-acc.router-link-exact-active {
        border-bottom: none !important;
        padding-bottom: 10px !important;
    }

    .pad-top{
        padding-right: 15px;
        /* text-align: center; */
        border-right: 1px solid #EEEEEE;
    }
    .pad-top:last-child{
        border-right: none;
    }
    .pad-top p:hover{
        cursor: pointer;
        color: #312783 !important;
        font-weight: bold;
    }
    .pad-top span:hover {
        cursor: pointer;
        color: #DE5D0A !important;
        font-weight: bold;
    }
    li a .nav_link{
        color: #fff !important;
        text-decoration: none;
        transition: all 0.5s ease;
    }
    li a:hover {
        color: #DE5D0A !important;
        font-weight: bold;
    }
    
    .language-popup {
        position: absolute;
        top: 44px;
        /* left: 0; */
        /* width: 142px; */
        border-top: 1px solid #EEEEEE;
        width: 150px;
        background-color: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 100;
        padding: 10px;
        /* color: white; */
        color: #1D1D1D;
        font-weight: 500;
        display: flex;
        flex-direction: column;
    }
    
    .language-popup div {
        cursor: pointer;
        padding: 7px;
        /* border-bottom: 1px solid #DE5D0A; */
    }

    .language-popup div:hover {
        background-color: #f0f0f0;
        color: #DE5D0A;
    }

    #menu_toggle {
        display: none;
    }
    .hidden {
        display: none;
    }
    .noneImgScreen{
        display: none !important;
    }

    .mobileul{
        list-style: none;
    }
    .mobileul li span{
        color: #1D1D1D !important;
        font-weight: 600;
    }
    .mobileul li div:hover{
        /* background-color: #f0f0f0; */
        color: #DE5D0A !important;
    }

    .slide-enter-active {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }

    .slide-leave-active {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to, .slide-leave {
        max-height: 100px;
        overflow: hidden;
    }

    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }

    /* Responsive */
    @media screen and (width < 950px){
        #menu_toggle {
            display: block !important;
        }
        .menu_items {
            display: none !important;
        }
        .nav {
            padding: 0 20px;
        }
        .menu_itemes {
            display: block !important;
            position: fixed;
            top: -19px;
            width: 260px;
            /* background: #DE5D0A; */
            /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
            background: #FFFFFF;
            /* height: 100vh; */
            left: -1px;
            padding: 50px 30px 30px;
            flex-direction: column;
            transition: all 0.5s ease;
            height: calc(115vh - 70px); /* Adjust the height according to your design */
            overflow-y: auto;
        }
        .destopApp{
            display: none;
        }
        .pad-top {
            padding-top: 15px;
            border-right: none;
            border-bottom: 1px solid #EEEEEE;
            padding-bottom: 15px;
        }
        .home{
            padding-top: 30px !important;
        }
        .pad-top:last-child{
            border-bottom: none;
        }
        
        #menu_toggle {
            width: 20px;
            font-size: 18px;
            cursor: pointer;
        }
        .menu_items #menu_toggle {
            position: absolute;
            top: 63px;
            right: 20px;
        }
        .hidden {
            display: block;
        }
        .noneHeader1{
            display: none !important;
        }
        .noneImgScreen{
            display: block !important;
        }
        .mobileScreenli{
            display: block !important;
        }
        .header{
            padding-bottom: 0px !important;
        }
        
        
    }
    @media screen and (width < 438px){
        .menu_itemes {
            display: block !important;
            position: fixed;
            top: -19px;
            width: 100% !important;
            /* background: #DE5D0A; */
            /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
            background: #FFFFFF;
            /* height: 120vh; */
            left: -1px;
            padding: 50px 30px 30px;
            flex-direction: column;
            transition: all 0.5s ease;
        }
        .home{
            padding-top: 30px !important;
        }
        .noneHeader1{
            display: none !important;
        }
        .noneImgScreen{
            display: block !important;
        }
        .mobileScreenli{
            display: block !important;
        }
        
    }
    /* End Header */
    .onScroll {
        z-index: 1001;
        /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
        background: #FFFFFF;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
    @media screen and (width < 500px){
        .header{
            padding-bottom: 0px !important;
        }
        .onScroll {
            /* background: #DE5D0A !important; */
            /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
            background: #FFFFFF;
            opacity: inherit;
        }
        .noneHeader1{
            display: none !important;
        }
        .noneImgScreen{
            display: block !important;
        }
        .mobileScreenli{
            display: block !important;
        }

    }
</style>