<template>
    <div>
        <HeaderComponent />
        <div class="feed-back">
            <div class="containers">
               <div class="card-survey">
                    <div class="survey">
                        <div class="langKh" style="text-align: center;font-size: 25px;">
                            ស្ទង់មតិពីសេវាកម្មរបស់យើង
                        </div>
                        <div style="text-align: center;font-size: 20px;font-weight: 600;">Survey your experience with our services</div>
                    </div>
                    <div class="question" v-for="(questionList, index) in questionLists" :key="questionList.id">
                        <div class="question-kh langKh">{{ questionList.nameKh }}</div>
                        <div class="question-en">{{ questionList.name }}</div>
                        <div class="mt-4" style="display: flex;">   
                            <div>
                                <img src="@/assets/feedback/emoji-01.png" style="width: 40px;margin-right: 5px;" alt="">
                            </div>
                            <div>
                                <img src="@/assets/feedback/emoji-02.png" style="width: 40px;margin-right: 5px;" alt="">
                            </div>
                            <div>
                                <img src="@/assets/feedback/emoji-03.png" style="width: 40px;margin-right: 5px;" alt="">
                            </div>
                            <div>
                                <img src="@/assets/feedback/emoji-04.png" style="width: 40px;margin-right: 5px;" alt="">
                            </div>
                            <div>
                                <img src="@/assets/feedback/emoji-05.png" style="width: 40px;margin-right: 5px;" alt="">
                            </div>
                        </div>
                        <div v-if="index == 0" style="padding-left: 11px;">
                            <div class="pretty p-icon p-round" style="width: 29px !important;" @click="setActive(index, 1)">
                                <input type="radio" name="icon_solid" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive(index, 2)">
                                <input type="radio" name="icon_solid" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive(index, 3)">
                                <input type="radio" name="icon_solid" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive(index, 4)">
                                <input type="radio" name="icon_solid" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                            <div class="pretty p-icon p-round" style="width: 32x !important;" @click="setActive(index, 5)">
                                <input type="radio" name="icon_solid" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                        </div>
                        <div v-if="index == 1" style="padding-left: 11px;">
                            <div class="pretty p-icon p-round" style="width: 29px !important;" @click="setActive1(index, 1)">
                                <input type="radio" name="icon_solid1" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive1(index, 2)">
                                <input type="radio" name="icon_solid1" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive1(index, 3)">
                                <input type="radio" name="icon_solid1" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive1(index, 4)">
                                <input type="radio" name="icon_solid1" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive1(index, 5)">
                                <input type="radio" name="icon_solid1" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                        </div>
                        <div v-if="index == 2" style="padding-left: 11px;">
                            <div class="pretty p-icon p-round" style="width: 29px !important;" @click="setActive2(index, 1)">
                                <input type="radio" name="icon_solid2" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive2(index, 2)">
                                <input type="radio" name="icon_solid2" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive2(index, 3)">
                                <input type="radio" name="icon_solid2" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive2(index, 4)">
                                <input type="radio" name="icon_solid2" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive2(index, 5)">
                                <input type="radio" name="icon_solid2" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                        </div>
                        <div v-if="index == 3" style="padding-left: 11px;">
                            <div class="pretty p-icon p-round" style="width: 29px !important;" @click="setActive3(index, 1)">
                                <input type="radio" name="icon_solid3" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive3(index, 2)">
                                <input type="radio" name="icon_solid3" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive3(index, 3)">
                                <input type="radio" name="icon_solid3" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActiv3(index, 4)">
                                <input type="radio" name="icon_solid3" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>

                            <div class="pretty p-icon p-round" style="width: 32px !important;" @click="setActive3(index, 5)">
                                <input type="radio" name="icon_solid3" />
                                <div class="state p-success">
                                    <i class="icon mdi mdi-check"></i>
                                    <label></label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-4">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <input type="text" v-model="name" class="custom-input" placeholder="Name">
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <input type="text" v-model="phone" class="custom-input" placeholder="Telephone">
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div style="position: relative;">
                                <input type="text" v-model="vehicleName" @click="getVehicle" placeholder="Vehicle ID" class="custom_select">
                                <div style="position: absolute;top:49px;width: 100%;">
                                    <div v-if="showListData" class="v-menu__content theme--light menuable__content__active v-autocomplete__content"  v-click-outside="hidePopup" style="max-height: 304px;min-width: 100%;transform-origin: left top; z-index: 8;">
                                        <div role="listbox" v-for="item in items" :key="item.id" class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                            <div role="option" class="v-list-item v-list-item--link theme--light" >
                                                <div class="v-list-item__content edit-list" style="padding: 13px !important;">
                                                    <div class="v-list-item__title" @click="handleClick(vehicleName = item.name, vehicleId = item.id)">{{ item.name }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <v-menu
                                ref="menu"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="time"
                                        label="Departure"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="menu2"
                                    v-model="time"
                                    full-width
                                    @click:minute="$refs.menu.save(time)"
                                ></v-time-picker>
                            </v-menu>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <v-textarea v-model="note" outlined name="input-7-4" label="Note"></v-textarea>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <v-btn depressed @click="saveFeedBack" color="success">Submit</v-btn>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                        
                        </div>
                    </div>
               </div>
            </div>  

            <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2"><span class="langKh">សារ</span>Message</v-card-title>

                        <v-card-text>
                            <p class="langKh">សូមអរគុណ ចំពោះការស្ទង់មតិពីសេវាកម្មរបស់យើង</p>
                            Thank you for survey your experience with our services.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="dialog = false"
                        >
                            Close
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
        <IncFooter />
    </div>
    
</template>
<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';
import axios from 'axios';
import qs from 'qs';
export default {
    components: {
        HeaderComponent,
        IncFooter
    },
    data() {
        return {
            dialog: false,
            items: [],
            time: null,
            menu2: false,
            modal2: false,
            showListData: false,
            questionLists: [],
            questionId: [],
            selection1: [],
            selection2: [],
            selection3: [],
            selection4: [],
            vehicleName: null,
            vehicleId: null,
            name: null,
            phone: null,
            note: null,
            busId: this.$route.query.busId,
            ticketId:this.$route.query.ticketId,
            feedBack: []
        }
    },
    methods: {
        async getQuestion() {
            const data = {
                "id": 0,
                "name": "",
                "nameKh": ""
            }
            const url = this.$url + `feedback/getQuestion`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            axios(config).then((response) => {
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.questionLists = response.data.body;
                        for(let i = 0; i<this.questionLists.length; i++) {
                            this.questionId.push(this.questionLists[i].id);
                        }
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async getVehicle() {
            this.showListData = true; 
            const data = {
                "id": 0,
                "name": "",
            }
            const url = this.$url + `feedback/getVehicle`;

            let config = {
                method: "POST",
                headers: this.$header,
                data: qs.stringify(data),
                url
            };

            axios(config).then((response) => {
                if(response.status == 200){
                    if(response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.items = response.data.body;
                        //console.log(this.vehicleId);
                        if(this.vehicleId == null){
                            for(let i = 0; i < this.items.length; i++){
                                if(this.busId == this.items[i].id){
                                    this.vehicleName = this.items[i].name;
                                    this.vehicleId = this.items[i].id;
                                    this.showListData = false; 
                                }
                            }
                        }
                        
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        showPopup(){
            this.showListData = true;
        },
        hidePopup() {
            this.showListData = false;
        }, 
        handleClick(vehicleName, vehicleId) {
            this.vehicleName = vehicleName;
            this.vehicleId = vehicleId;
            this.showListData = false; 
        },
        setActive(index, value) {
            this.selection1 = [];
            this.selection1 = value;
            this.selection = this.selection1;
            localStorage.setItem("selection1", this.selection1);
            this.activeIndex = index;
        },
        setActive1(index, value) {
            this.selection2 = [];
            this.selection2 = value;
            localStorage.setItem("selection2", this.selection2);
            this.activeIndex = index;
        },
        setActive2(index, value) {
            this.selection3 = [];
            this.selection3 = value;
            localStorage.setItem("selection3", this.selection3);
            this.activeIndex = index;
        },
        setActive3(index, value) {
            this.selection4 = [];
            this.selection4 = value;
            localStorage.setItem("selection4", this.selection4);
            this.activeIndex = index;
        },
        async saveFeedBack() {
            let selection = [];
            let selection1 = localStorage.getItem("selection1");
            let selection2 = localStorage.getItem("selection2");
            let selection3 = localStorage.getItem("selection3");
            let selection4 = localStorage.getItem("selection4");
            selection.push(selection1);
            selection.push(selection2);
            selection.push(selection3);
            selection.push(selection4);
                
            if(selection1 !== null && selection2 !== null && selection3 !== null && selection4 !== null) {
                if(this.vehicleName !== null && this.vehicleId !== null && this.name !== null && this.phone !== null || this.time !== null) {
                    const data = {
                        'departure'      : this.time,
                        'name'           : this.name,
                        'note'           : this.note,
                        'questionId'     : this.questionId,
                        'scoreRate'      : selection,
                        'telephone'      : this.phone,
                        'ticketId'       : this.ticketId,
                        'vehicleId'     : this.vehicleId,
                        
                    }
                    const url = this.$url + `feedback/save`;
                    let config = {
                        method: "POST",
                        headers: this.$header,
                        data: qs.stringify(data),
                        url
                    };

                    axios(config).then((response) => {
                        // console.log(response);
                        if(response.status == 200){
                            if(response.data.header.result == true && response.data.header.statusCode == 200) {
                                this.feedBack = response.data.body;
                                if(this.feedBack.message == "Success") {
                                    this.dialog = true;
                                }else{
                                    alert("Please input all information before submit.");
                                }
                            }
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }else {
                    alert("Please input all information before submit.");
                }
            }else {
                alert("Please input all information before submit.");
            }    
        },
    },
    created() {
        if(this.selection1 == 0 || this.selection2 == 0 || this.selection3 == 0 || this.selection4 == 0) {
            localStorage.removeItem("selection1");
            localStorage.removeItem("selection2");
            localStorage.removeItem("selection3");
            localStorage.removeItem("selection4");
        }
        this.getQuestion();
        this.getVehicle();
    }
}
</script>

<style scoped>
    .feed-back {
        padding-top: 120px;
        padding-bottom: 50px;
    }
    .card-survey {
        width: 100%;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .question {
        padding-top: 17px;
        padding-bottom: 20px;
    }
    .question-kh {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .question-en {
        font-weight: 600;
        font-size: 16px;
    }  
    .custom-input {
        cursor: pointer;
        padding-left: 12px;
        border-radius: 4px;
        width: 100%;
        height: 55px;
        outline: none;
        border: 1px solid #b7b7b9;
    }
    .custom_select {
        padding: 4px 10px 2px;
        outline: none;
        border-radius: 4px;
        height: 55px;
        width: 100%;
        border: 1px solid #c6c6c6;
        background: #ffffff;
        color: #1d1d1d;
        font-weight: 500;
    }
    .custom_select:hover{
        cursor: pointer;
    }
    .edit-list:hover {
        background: #1867c0;
        color: #FFF;
        cursor: pointer;
        border-radius: 2px;
    }
    .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
        align-items: center;
        display: inline-flex;
        flex: 1 1 auto;
        height: auto;
        color: #312783;
    }
</style>