<template>
    <!-- <div v-if="showPopup" class="popup"> -->
            <!-- screen md sm -->
            <v-row v-if="!shouldHideDialog" justify="center" >
                <v-dialog
                v-model="showPopup"
                persistent
                max-width="65%"
                style="z-index: 20020 !important;"
                >
                <div style="width: 100%; height:100%; position: relative;" class="m-auto-o">
                    <img src="@/assets/carousel/advs-new-route.jpg" class="mb-img" alt="" style="width:100%; height: 100%;">
                    <div class="d-flex position-absolute">
                      <div class="mr-2"> SKIP   {{ remainingTime }}</div>
                      <div class="box-x" @click="closePopup"><img  src="@/assets/arrows/x.svg" alt=""></div>
                      
                    </div>
                </div>
               
                </v-dialog>
            </v-row>
       
            <v-row  v-else justify="center" >
                <v-dialog
                v-model="showPopup"
                persistent
                max-width="100%"
                style="z-index: 20020 !important;"
                >
                <div style="width: 100%; height:100%; position: relative;" class="m-auto-o">
                    <img src="@/assets/carousel/advs-new-route.jpg" class="mb-img" alt="" style="width:100%; height: 100%;">
                    <div class="d-flex position-absolute">
                      <div class="mr-2"> SKIP   {{ remainingTime }}</div>
                      <div class="box-x" @click="closePopup"><img src="@/assets/arrows/x.svg" alt=""></div>
                      
                    </div>
                </div>
                </v-dialog>
            </v-row>
        
        
    <!-- </div> -->
    
  </template>
  
  <script>
  export default {
    data() {
      return {
        showPopup: false,
        remainingTime: 5, // in second
        intervalId: null,
        setAdspopup: localStorage.getItem('adspopup')
      };
    },
    methods: {
      startTimer() {
      // Update remaining time every minute
        this.intervalId = setInterval(() => {
          if (this.remainingTime > 1) {
            this.remainingTime--;
          } else {
            this.closePopup();
          }
        }, 1000); // 1 second interval
      },
      closePopup() {
        clearInterval(this.intervalId);
        this.showPopup = false;
        this.removefixscroll();
      },
      fixscrollshow(){
        const htmlElement = document.querySelector('html');
        htmlElement.classList.add('fix-scroll');
      },
      removefixscroll(){
          const htmlElement = document.querySelector('html');
          htmlElement.classList.remove('fix-scroll');
      },
      showPopupOnlyone(){
        if(this.setAdspopup == 1){
          this.showPopup = false;
        }else{
          localStorage.setItem('adspopup','1');
          setTimeout(() => {
            this.startTimer();
            this.showPopup = true;
            this.fixscrollshow();
          }, 1500);
        }
       
      },
      handleBeforeUnload() {
        // Clear localStorage
        localStorage.setItem('adspopup','2');
         // Optionally, close the tab/window
      }
    },
    computed:{
      shouldHideDialog() {
          // console.log(this.$vuetify.breakpoint.xsOnly)
          return this.$vuetify.breakpoint.xsOnly;
      },
      // shouldHideDialogOnLg() {
      //     // console.log(this.$vuetify.breakpoint.xsOnly)
      //     return this.$vuetify.breakpoint.lgOnly;

      // },
    },
    created(){
        // localStorage.setItem('adspopup','0');
        localStorage.setItem('adspopup','1');
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        
       
        
    },
    mounted() {
      // Example: Show pop-up after 1 seconds
      this.showPopupOnlyone();
    },
    beforeDestroy() {
      // Remove event listener before destroying the component
      window.removeEventListener('beforeunload', this.handleBeforeUnload);

    }
  };
  </script>
  
  <style scoped>
    .v-dialog__content--active{
      z-index: 1000000000 !important;
    }
    .width-max{
      max-width: 60%;
    }
    .v-dialog--active{
      margin-top: 10% !important;
    }
    .mb-img{
       margin-bottom: -5px;
    }
    .screen-large{
        display: block !important;
    }
    .screen-mobile{
        display: none !important;
    }
    .position-absolute{
      position: absolute;
      right: 15px;
      top: 10px;
    }
    .box-x{
      width: 24px;
      height: 24px;
      background: white;
      cursor: pointer;
    }
    .box-x:hover{
      opacity: 1;
    }
    @media (min-width: 1540px) and (max-width: 1904px) {
      .v-dialog--active{
        max-width: 55% !important;
        margin-top: 10% !important;
      }
    }
    @media (min-width: 600px) and (max-width: 1024px) {
      .v-dialog--active{
        max-width: 90% !important;
        margin: 20px !important;
      }
    }
    @media screen and (max-width: 500px) {
        .screen-mobile{
            display: block !important;
        }
        .screen-large{
            display: none !important;
        }
    }

  </style>
  