var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel_his"},[_c('div',{staticClass:"container-fluid",staticStyle:{"background":"#f2f2f2"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row header-profile"},[_c('div',{staticClass:"d-flex",staticStyle:{"border-bottom":"1px solid #C6C6C6","width":"100%"}},[_c('div',{staticClass:"account-ticket account-ticket-1",class:{ active: _vm.activeTab == '1' },on:{"click":function($event){return _vm.toggleTab('1')}}},[_vm._v(" Account Ticket ")]),_c('div',{staticClass:"account-ticket",class:{ active: _vm.activeTab == '2' },staticStyle:{"margin-left":"30px"},on:{"click":function($event){return _vm.toggleTab('2')}}},[_vm._v(" Travel Package History ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab==1),expression:"activeTab==1"}],staticClass:"account-ticket-history mt-8"},[_vm._m(0),_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab==2),expression:"activeTab==2"}],staticClass:"travel-package-history mt-8"},[_vm._m(2),_vm._m(3),_c('div',{staticClass:"row mt-8 inf-invoice"},[_c('div',{staticClass:"col-lg-10 col-md-10 col-sm-10 info-package-buy d-flex align-center"},[_vm._m(4),_c('div',{staticClass:"title-car ml-5"},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v("Code Travel: 000000001")]),_vm._v(" "),_c('span',{staticClass:"d-flex justify-center copy-past",on:{"click":function($event){return _vm.Copytitle('000000001')}}},[_c('img',{staticStyle:{"margin-left":"10px","margin-right":"3px"},attrs:{"src":require("@/assets/travel_package/copy.svg"),"alt":""}}),_vm._v(" "+_vm._s(this.copyStatus))])]),_vm._m(5),_vm._m(6)])])]),_vm._m(7)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"count-ticket mr-4 mt-3"},[_c('img',{attrs:{"src":require("@/assets/travel_package/Ellipse 15.png"),"alt":""}}),_c('div',[_vm._v(" 1/10 ")])]),_c('div',{staticClass:"inf-account d-flex flex-column justify-space-around"},[_c('div',[_c('span',{staticClass:"tel"},[_vm._v("Tel:")]),_vm._v(" "),_c('span',{staticClass:"number"},[_vm._v(" 012 345 678")])]),_c('div',{staticClass:"box-account"},[_vm._v(" General Account ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"mb-3",staticStyle:{"font-weight":"bold"}},[_vm._v("Condition "),_c('span',{staticStyle:{"color":"#DE5D0A"}},[_vm._v(" *")])]),_c('ul',{staticStyle:{"list-style-type":"decimal"}},[_c('li',[_vm._v("Buy 10 tickets you will become VIP Customer.")]),_c('li',[_vm._v("You will get 1 ticket for free on every 11th ticket.")]),_c('li',[_vm._v("VIP customer get $1 discount on each ticket.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h3',{staticClass:"title-package"},[_vm._v("Domestic Travel Package (Cambodia) – 96USD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-10"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/travel_package/default_pf.svg"),"alt":"","width":"160px","height":"160px"}})]),_c('div',{staticClass:"information-travel",staticStyle:{"margin-top":"25px","margin-left":"20px"}},[_c('ul',[_c('li',[_vm._v("TEKLY Online")]),_c('li',[_vm._v("017848185")]),_c('li',[_vm._v("tekly@gmail.com")]),_c('li',[_vm._v("Price $240")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-car d-flex align-center"},[_c('img',{attrs:{"src":require("@/assets/travel_package/truck.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Issue Date: 2024-01-15")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Expired Date: 2024-12-15")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-6"},[_c('div',{staticClass:"col-10 mb-3"},[_c('span',{staticStyle:{"font-weight":"bold","color":"#312783"}},[_vm._v("Term & Condition:")]),_c('div',{staticClass:"mt-2",staticStyle:{"color":"#424245"}},[_vm._v(" The content of this product is provided by machine translation and may not reflect the actual information, please take this into consideration before booking. "),_c('br'),_c('span',[_vm._v("- Dubai sightseeing single 30-day visa (excluding insurance) agency specifications -")]),_c('ul',[_c('li',[_vm._v("Visa type: Dubai visa.")]),_c('li',[_vm._v("Visa validity: 60 days, invalid after expiration .")]),_c('li',[_vm._v("Number of entries: single .")]),_c('li',[_vm._v("Number of days allowed to stay: 30 days.")])])])])])
}]

export { render, staticRenderFns }