<template>
    <div class="service-rental">
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else class="containers set-height">
            <div class="service">
                <span class="com">{{$t('message.service')}}</span>
            </div>
            <div class="cam-vi-th">
                <span>{{$t('message.vehicleRentalInCambodiaThailandVietnam')}}</span>
            </div>
            <div class="text-dec">
                <div v-if="seeMore">
                    <span class="line-clamp">
                        {{$t('message.desVehicleRentalInCambodiaThailandVietnamLess')}}
                        <span @click="seeMoreView()" style="color: #312783;cursor: pointer;">{{$t('message.seeMore')}}</span> 
                    </span>
                   
                </div>
                <span v-else>
                    {{$t('message.desVehicleRentalInCambodiaThailandVietnamMore')}} 
                    <span @click="seeMoreView()" style="color: #312783;cursor: pointer;">{{$t('message.seeLess')}}</span> 
                </span>
            </div>
            <div class="text-email">
                <div class="info-ask">
                    {{$t('message.askInfoOrBookVia')}}
                </div>
                &nbsp;&nbsp;
                <div style="display: flex;">
                    <div class="telegram">
                        <a href="https://t.me/vetairbusexpress" target="blank">
                            <img src="@/assets/icon-social-media/telegram.svg" style="width: 35px;border-radius: 8px;" alt="">
                            <span style="color: #E38F5A;font-size: 16px;font-weight: 600;">Telegram</span>
                        </a>
                    </div>
                    <!-- &nbsp;&nbsp;&nbsp;
                    <div class="telegram">
                        <a :href="generateMailtoLink" target="blank">
                            <img src="@/assets/icon-social-media/email.svg" style="width: 35px;border-radius: 8px;" alt="">
                            <span style="color: #E38F5A;font-size: 16px;font-weight: 600;">Email</span>
                        </a>
                    </div> -->
                </div>
                
            </div>
            <div class="row" style="margin-top: 10px;" v-for="(data, index) in rentalPage" :key="data.id">
                <!-- Left -->
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 respo-img" v-if="calculateOddEven(index) == 0">
                    <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                        <vueper-slide style="height: 100%;"
                            v-for="(slide, i) in data.slidePhoto"
                            :key="i"
                            :image="data.slidePhoto.length != 0 ? slide.photo : require('@/assets/placeholder-image.jpeg')"
                        />
                    </vueper-slides>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" :class="{ 'mobile-height': getParagraphs(data.description).length < 8 }" v-if="calculateOddEven(index) == 0">
                    <div class="hyundai">
                        <div class="hyundai-univers pb-4">{{ data.name }}</div> 
                        <div v-for="(paragraph, index) in getParagraphs(data.description)" :key="index" class="pb-2">
                            {{ paragraph }}
                        </div>
                        
                        
                        <div class="btn-servation" @click="bookingDetail(data.id, data.name, data.slidePhoto)">
                            {{$t('message.reservationNow')}}
                        </div>
                    </div>
                </div>
                 <!-- right -->
                 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 respo-img mobileScreenlayoutypebus" v-if="calculateOddEven(index) == 1">
                    <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                        <vueper-slide style="height: 100%;"
                            v-for="(slide, i) in data.slidePhoto"
                            :key="i"
                            :image="slide.photo"
                        />
                    </vueper-slides>
                </div>
                 <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" :class="{ 'mobile-height': getParagraphs(data.description).length < 8 }" v-if="calculateOddEven(index) == 1">
                    <div class="hyundai">
                        <div class="hyundai-univers pb-4">{{ data.name }}</div>
                        <div v-for="(paragraph, index) in getParagraphs(data.description)" :key="index" class="pb-2">
                            {{ paragraph }}
                        </div>
                        <!-- <div class="pb-2">Seating capacity: Up to 41 passengers</div>
                        <div class="pb-2">Seat Plan: 2 + 2 </div>
                        <div class="pb-2">Model: Hyundai Universe Noble</div>
                        <div class="pb-2">Production Year: 2010</div>
                        <div class="pb-2">GdivS Tracking System: Yes</div>
                        <div class="pb-2">Restroom: Yes</div>
                        <div class="pb-2">Refrigerator: Yes</div>
                        <div class="pb-2">Power Outlet: Yes</div>
                        <div class="pb-2">Wi-Fi: Yes</div>
                        <div class="pb-2">Sound System: Yes</div>
                        <div class="pb-2">Driving License :  D4</div>
                        <div class="pb-2">Insurance: Passenger Liability</div> -->
                        <div class="btn-servation" @click="bookingDetail(data.id, data.name, data.slidePhoto)">
                            {{$t('message.reservationNow')}}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 respo-img notonlymobile" v-if="calculateOddEven(index) == 1">
                    <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                        <vueper-slide style="height: 100%;"
                            v-for="(slide, i) in data.slidePhoto"
                            :key="i"
                            :image="slide.photo"
                        />
                    </vueper-slides>
                </div>
            </div>
            <!-- <div class="row" style="margin-top: 10px;">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="hyundai">
                        <div class="hyundai-univers pb-4">Premium Hyundai Solati H350</div>
                        <div class="pb-2">Seating capacity: Up to 8 passengers</div>
                        <div class="pb-2">Massage Seats: 4 seats</div>
                        <div class="pb-2">Model: Hyundai Solati H350</div>
                        <div class="pb-2">Production Year: 2020 – 2022</div>
                        <div class="pb-2">GPS Tracking System: Yes</div>
                        <div class="pb-2">Refrigerator: Yes</div>
                        <div class="pb-2">USB Charger: Yes</div>
                        <div class="pb-2">Wi-Fi: Yes</div>
                        <div class="pb-2">Sound System: Yes</div>
                        <div class="pb-2">Driving License :  D4</div>
                        <div class="pb-2">Insurance: Passenger Liability</div>
                        <div class="btn-servation"><a href="/booking-detail" style="color: #fff;">Reservation Now…</a></div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                        <vueper-slide style="height: 100%;"
                            v-for="(slide, i) in slides"
                            :key="i"
                            :image="slide.image"
                        />
                    </vueper-slides>
                </div>
            </div> -->
        </div>
        <!-- <div class="banner">
            <div class="containers">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div class="hyundai">
                            <div class="hyundai-univers pb-4" style="color: #fff">Classic Hyundai Solati H350 </div>
                            <div class="pb-2" style="color: #fff">Seating capacity: Up to 12 passengers</div>
                            <div class="pb-2" style="color: #fff">Model: Hyundai Solati H350</div>
                            <div class="pb-2" style="color: #fff">Production Year: 2020 – 2022</div>
                            <div class="pb-2" style="color: #fff">GPS Tracking System: Yes</div>
                            <div class="pb-2" style="color: #fff">USB Charger: Yes</div>
                            <div class="pb-2" style="color: #fff">Wi-Fi: Yes</div>
                            <div class="pb-2" style="color: #fff">Sound System: Yes</div>
                            <div class="pb-2" style="color: #fff">Driving License :  D4</div>
                            <div class="pb-2" style="color: #fff">Insurance: Passenger Liability</div>
                            <div class="btn-servation"><a href="/booking-detail" style="color: #fff;">Reservation Now…</a></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                            <vueper-slide style="height: 100%;"
                                v-for="(slide, i) in slides"
                                :key="i"
                                :image="slide.image"
                            />
                        </vueper-slides>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div class="containers">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                        <vueper-slide style="height: 100%;"
                            v-for="(slide, i) in slides"
                            :key="i"
                            :image="slide.image"
                        />
                    </vueper-slides>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="hyundai">
                        <div class="hyundai-univers pb-4">Luxury Hyundai H1</div>
                        <div class="pb-2">Seating capacity: Up to 6 passengers</div>
                        <div class="pb-2">Massage Seats: 2</div>
                        <div class="pb-2">Model: Hyundai H1</div>
                        <div class="pb-2">Production Year: 2020</div>
                        <div class="pb-2">GdivS Tracking System: Yes</div>
                        <div class="pb-2">USB Charger: Yes</div>
                        <div class="pb-2">Wi-Fi: Yes</div>
                        <div class="pb-2">Sound System: Yes</div>
                        <div class="pb-2">Driving License :  D4</div>
                        <div class="pb-2">Insurance: Passenger Liability</div>
                        <div class="btn-servation"><a href="/booking-detail" style="color: #fff;">Reservation Now…</a></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="hyundai">
                        <div class="hyundai-univers pb-4">Classic Hyundai H1</div>
                        <div class="pb-2">Seating capacity: Up to 8 passengers</div>
                        <div class="pb-2">Model: Hyundai H1</div>
                        <div class="pb-2">Production Year: 2020 – 2022</div>
                        <div class="pb-2">GPS Tracking System: Yes</div>
                        <div class="pb-2">USB Charger: Yes</div>
                        <div class="pb-2">Wi-Fi: Yes</div>
                        <div class="pb-2">Sound System: Yes</div>
                        <div class="pb-2">Driving License :  D4</div>
                        <div class="pb-2">Insurance: Passenger Liability</div>
                        <div class="btn-servation"><a href="/booking-detail" style="color: #fff;">Reservation Now…</a></div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                        <vueper-slide style="height: 100%;"
                            v-for="(slide, i) in slides"
                            :key="i"
                            :image="slide.image"
                        />
                    </vueper-slides>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <vueper-slides :dragging-distance="50" :arrows="false" height="100%" style="height: 100%;">
                        <vueper-slide style="height: 100%;"
                            v-for="(slide, i) in slides"
                            :key="i"
                            :image="slide.image"
                        />
                    </vueper-slides>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="hyundai">
                        <div class="hyundai-univers pb-4">Hyundai County</div>
                        <div class="pb-2">Seating capacity: Up to 16 passengers</div>
                        <div class="pb-2">Seat plan: 2 + 1 </div>
                        <div class="pb-2">Model: Hyundai H1</div>
                        <div class="pb-2">Production Year: 2020</div>
                        <div class="pb-2">GdivS Tracking System: Yes</div>
                        <div class="pb-2">USB Charger: Yes</div>
                        <div class="pb-2">Wi-Fi: Yes</div>
                        <div class="pb-2">Sound System: Yes</div>
                        <div class="pb-2">Driving License :  D4</div>
                        <div class="pb-2">Insurance: Passenger Liability</div>
                        <div class="btn-servation"><a href="/booking-detail" style="color: #fff;">Reservation Now…</a></div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import axios from 'axios';
export default {
    components: { VueperSlides, VueperSlide },
    data () {
        return {
            loading: false,
            seeMore: true,
            rentalPage: [],
        }
    },
    computed: {
        generateMailtoLink() {
            const recipient = 'support@vireakbuntham.com';
            const subject = '';
            const body = '';
            const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(recipient)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            return gmailUrl;
        }
    },
    methods: {
        seeMoreView() {
            this.seeMore = !this.seeMore;
        },
        getParagraphs(description) {
            // Split the description into paragraphs using \n as delimiter
            return description.split('\n').map(paragraph => paragraph.trim());
        },
        async getDataVehicleRental() {
            const url = this.$url + `vehicle-rental/busType`;
            let config = {
                method: "POST",
                headers: this.$header,
                url,
            };
            this.loading = true;
            axios(config).then((response) => {
                this.loading = false;
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.rentalPage = response.data.body.data;
                    }
                } 
            }).catch((error) => {
                console.log(error);
            });
        },
        calculateOddEven(number) {
            if(number % 2 === 0) {
                return 0;
            } else {
                return 1;
            }
        },
        bookingDetail(id, name, slidePhoto) {
            this.$router.push({ 
                path: '/booking-detail',
                query: { 
                    id: id,
                    name: name,
                    slides: JSON.stringify(slidePhoto)
                } 
            });
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    },
    created() {
        this.scrollToTop();
        this.getDataVehicleRental();
    }
}
</script>

<style scoped>
    .service-rental {
        margin-top: 100px;
        padding-bottom: 50px;
    }
    .set-height {
        padding-bottom: 50px;
    }
    .info-ask{
        color: #424245;font-size: 24px;font-weight: 400;
    }
    .service {
        text-align: center;
    }
    .service span {
        font-size: 15px;
        font-weight: 400;
        color: #DE5D0A;
        letter-spacing: 2.3px;
    }
    .cam-vi-th {
        text-align: center;
    }
    .cam-vi-th span {
        font-weight: 600;
        font-size: 36px;
        color: #000000;
    }
    .mobile-height{
        height: 450px;
    }
    .notonlymobile{
        display: block !important;
    }
    .mobileScreenlayoutypebus{
        display: none !important;
    }
    .vueperslide__title {
        font-size: 36px;
        color: #fff;
        font-weight: 600;
        position: absolute;
        bottom: 15%;
    }
    .text-dec {
        width: 740px;
        margin: auto;
        text-align: center;
    }
    .text-dec p{
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        line-height: 1.4;
    }
    .text-email {
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    @media(width < 616px) {
        .text-email {
            /* display: inherit; */
        }
        .text-email .telegram{ 
            /* margin-top: 10px; */
        }
    }
    /* .text-email .telegram{ 
        width: 50%;
    } */
    .text-email .telegram{ 
        width: 100%;
    }
    .text-email .telegram a{
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #C6C6C6;
        align-items: center;
        border-radius: 8px;
    }
    .text-email .telegram a img {
        padding-right: 10px;
    }
    @media(width < 812px) {
        .text-dec {
            width: 100%;
        }
    }
    .hyundai {
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .hyundai .hyundai-univers{
        font-weight: 600;
        font-size: 36px;
        color: #1D1D1D;
    }
    .btn-servation {
        cursor: pointer;
        width: 170px;
        height: 45px;
        color: #fff;
        line-height: 45px;
        text-align: center;
        border: 1px solid #DE5D0A;
        background: #DE5D0A;
    }
    @media (width < 965px) {
        .hyundai .hyundai-univers{
            font-size: 20px;
        }
    }
    .banner {
        background: #3A5888;
        display: flex;
        align-items: center;
        height: 460px;
        margin-bottom: 40px;
    }
    @media (width < 600px) {
        .banner {
            height: 700px;
        }
        .notonlymobile{
            display: none !important;
        }
        .mobileScreenlayoutypebus{
            display: block !important;
        }
        .respo-img {
            height: 260px;
        }
        .mobile-height{
            height: auto !important;
        }
    }
    @media (width < 450px) {
        .cam-vi-th span {
            font-size: 24px;
        }
        .respo-img {
            height: 260px;
        }
    }

    @media (width < 400px) {
        .info-ask{
            font-size: 18px;
        }
    }
    
</style>