<template>
  <div class="card">
    <div class="containers">
      <div class="mainTitle">{{$t('message.vetSubsidiaries')}}</div>
      <div class="group-title">
        <h4 class="title">{{$t('message.vetSubsidiaries1')}}</h4>
        <ul>
          <li>
            Sara Sea Resort Co., Ltd,
            <span
              ><a href="https://sarasearesort.asia"
                >https://sarasearesort.asia</a
              ></span
            >
          </li>
          <li>
            Tube Resort Co., Ltd,
            <span
              ><a href="http://www.tuberesort.com"
                >http://www.tuberesort.com</a
              ></span
            >
          </li>
          <li>
            Bodega Resort Co., Ltd,
            <span
              ><a href="https://bodegabeachclub.com"
                >https://bodegabeachclub.com</a
              ></span
            >
          </li>
          <li>
            Sandy Resorts Co., Ltd,
            <span
              ><a href="https://sarasearesort.asia"
                >https://sandybeachbungalows.com</a
              ></span
            >
          </li>
          <li>
            Koh Sdach Resort
            <span
              ><a href="https://www.facebook.com/kohsdechresort"
                >https://www.facebook.com/kohsdechresort</a
              ></span
            >
          </li>
          <li>
            Eden Beach Resort
            <span
              ><a href="https://www.facebook.com/profile.php?id=100028389535319"
                >https://www.facebook.com/profile.php?id=100028389535319</a
              ></span
            >
          </li>
          <li>
            Apex Koh Kong Hotel,
            <span
              ><a href="https://apexkohkong.com"
                >https://apexkohkong.com</a
              ></span
            >
          </li>
          <li>Thansour Stoeung Tatai Co., Ltd</li>
          <li>
            Pidoma Resort, Mondulkiri
            <span
              ><a href="https://www.facebook.com/pidomaresort"
                >https://www.facebook.com/pidomaresort</a
              ></span
            >
          </li>
          <li>
            Camping Park, Kirirom
            <span
              ><a href="https://www.facebook.com/CampingParkKirirom"
                >https://www.facebook.com/CampingParkKirirom</a
              ></span
            >
          </li>
          <li>
            Romdoul Kirirom Resort , Kirirom
            <span
              ><a href="https://www.facebook.com/profile.php?id=100091229344637"
                >https://www.facebook.com/profile.php?id=100091229344637
              </a></span
            >
          </li>
          <li>
            Koh Apkijun Resort, Koh Kong
            <span
              ><a href="https://www.facebook.com/KohApikjunResort"
                >https://www.facebook.com/KohApikjunResort</a
              ></span
            >
          </li>
        </ul>
      </div>
      <div class="group-title">
        <h4 class="title">{{$t('message.vetSubsidiaries2')}}</h4>
        <ul>
          <li>
            Buva Sea (Cambodia) Co., Ltd,
            <span
              ><a href="https://sarasearesort.asia">www.buvasea.com</a></span
            >
          </li>
          <li>
            VET Air Bus Express,
            <span
              ><a href="https://sarasearesort.asia">www.vetairbus.com</a></span
            >
          </li>
          <li>
            V-Phsar Commercial App for online selling product and logistics
          </li>
          <li>
            VET Digital/Vtenh Commercial App for online selling products and
            logistics
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  components: {},
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    getBusTitle() {
      return localStorage.getItem("busTittle");
    },
  },
  computed: {},
};
</script>
    
    <style scoped>
.card {
  margin-top: 120px;
  margin-bottom: 50px;
}
.mainTitle {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  color: #de5d0a;
}
.group-title {
  padding-top: 40px;
}
.title {
  font-weight: 500;
  font-size: 20px;
  color: #de5d0a;
}
ul {
  padding-left: 70px;
}
ul li {
  color: #424242;
  padding-top: 12px;
  font-size: 15px;
  font-weight: 400;
}
ul li span a {
  color: #312783;
}
@media (width < 500px) {
  .card {
    margin-top: 100px;
  }
        
}
</style>