<template>
    <div class="shedule">
        <div class="header-menu">
            <HeaderComponent />
        </div>
        <div class="desktop">
            <SheduleList />
        </div>
        <div class="mobile-app">
            <MoblieScreen />
        </div>
        <div>
            <IncFooter />
        </div>
       
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import SheduleList from '@/components/Shedule/SheduleList.vue'
import IncFooter from '@/components/footer/IncFooter.vue';
import MoblieScreen from '@/components/moblie/moblieScreen.vue';
export default {
  components: { 
    SheduleList,
    HeaderComponent,
    IncFooter, 
    MoblieScreen 
},
    
data() {
    return {
    
    }
},
methods: {},

mounted() {
    window.onpopstate = () => {
        localStorage.removeItem("seat");
        localStorage.removeItem("seatlabel");
    };
},

created() {}
        
}
</script>

<style scoped> 
    .mobile-app {
        display: none;
    }
    @media(width < 960px) {
        .desktop {
            display: none;
        }
        .mobile-app {
            display: block;
        }
    }
</style>