<template>
    <div class="relatived">
        <div class="card-positions containers">
            <!-- <JourneyBegin /> -->
            <FormCard />
        </div>
    </div>
    
</template>

<script>
// import JourneyBegin from '../backgroundImages/JourneyBegin.vue'
import FormCard from './FormCard.vue'
export default {
    components: { 
        // JourneyBegin,
        FormCard
    },
    data() {
        return {

        }
    },
    methods: {
        // Set click cambodia and non cambodia
        clickCambodia() {
            localStorage.setItem('selectNational', 1);
        },
        clickNonCambodia (){
            localStorage.setItem('selectNational', 2);
        },
    },
    created() {

    }
    
}
</script>

<style lang="scss" scoped>
    .card-positions {
        width: 100%;
        position: absolute;
        top: 46%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
</style>