<template>
  <div class="profile-company">
    <div class="containers">
      <div class="company-profile">
        <span class="com">{{$t('message.companyProfile')}}</span><br />
        <span class="title-font">
          {{$t('message.enabling')}} <br />
          {{$t('message.KeySuccess')}}</span
        >
      </div>
      <div class="row" style="margin-top: 10px; margin-bottom: 20px">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="img-company">
            <img src="@/assets/about/6.jpg" alt="" />
          </div>
        </div>
        <div class="col-lg-6 ol-md-6 col-sm-12 col-xs-12">
          <div class="vet-background">
            <p class="vet">{{$t('message.vetBackground')}}</p>
            <p class="text-desc">
              {{$t('message.desVetBackground1')}}
            </p>
            <div class="set-width">
              <router-link to="/vet-back" style="color: #fff">
                <div class="btn-read-more">{{$t('message.readMore')}}</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-4 col-sm-6 col-xs-12"
          v-for="getprofiles in items"
          :key="getprofiles.id"
        >
          <div class="other_img">
            <img :src="getprofiles.image" alt="" />
            <router-link :to="getprofiles.link">
              <div class="btn-re-more">
                {{$t('message.readMore')}}
                <img
                  src="@/assets/Arrow.svg"
                  style="width: 25px; margin-left: 7px"
                  alt=""
                />
              </div>
            </router-link>
          </div>
          <div class="text-vet-milestone">
            <span class="vet-mil">{{ $t(getprofiles.title) }}</span
            ><br />
            <span class="smail-found">{{ $t(getprofiles.description) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          link: "/milestion",
          image: require("@/assets/about/1.jpg"),
          title: 'message.vetMilestone',
          description: 'message.desVetMilestone',
        },
        {
          id: 2,
          link: "/mission",
          image: require("@/assets/about/5.jpg"),
          title: 'message.visionMission',
          description: 'message.desVisionMision',
        },
        {
          id: 3,
          link: "/boarding",
          image: require("@/assets/about/2.jpg"),
          title: 'message.messageFromChairmanCeo',
          description: 'message.desMessageFromChairmanCeo',  
        },
        {
          id: 4,
          link: "/vet-value",
          image: require("@/assets/about/3.jpg"),
          title: 'message.vetCorporationCoreValues',
          description: 'message.desVetCorporationCoreValues',   
        },
        {
          id: 5,
          link: "/product-service",
          image: require("@/assets/about/4.jpg"),
          title: 'message.vetProductServices',
          description: 'message.desVetProductServices',  
        },
        {
          id: 6,
          link: "/vetMembershipCard",
          image: require("@/assets/about/10.jpg"),
          title: 'message.vetMembershipCard',
          description: "",
        },
        {
          id: 7,
          link: "/whyPartnerWithUs",
          image: require("@/assets/about/7.jpg"),
          title: 'message.whyPartnerWithUs',
          description: "",
        },
        {
          id: 8,
          link: "/vetVehicleType",
          image: require("@/assets/about/8.jpg"),
          title: 'message.vetVehicletype',
          description: "",
        },
        {
          id: 9,
          link: "/vetSubsidiaries",
          image: require("@/assets/about/9.jpg"),
          title: 'message.vetSubsidiaries',
          description: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.profile-company {
  width: 100%;
  margin-top: 100px;
  padding-bottom: 50px;
}
.company-profile .com {
  font-size: 15px;
  font-weight: 400;
  color: #de5d0a;
  letter-spacing: 2.3px;
}
.company-profile span {
  font-size: 48px;
  font-weight: 600;
}
.img-company {
  display: flex;
  width: 100%;
  height: 100%;
  line-height: 290px;
}
.img-company img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  object-fit: cover;
}
.vet-background .vet {
  font-weight: 600;
  font-size: 36px;
  color: #1d1d1d;
  line-height: 31px;
}
.vet-background .text-desc {
  font-weight: 400;
  font-size: 16px;
}
.btn-read-more {
  cursor: pointer;
  width: 170px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  background: #de5d0a;
  border: 1px solid #de5d0a;
}
.other_img {
  width: 100%;
  position: relative;
  height: 220px;
  object-fit: cover;
}
@media (min-width: 1550px) and (max-width: 2649px) {
  .other_img {
    height: 320px;
  }
  .img-company img {
    height: 420px;
  }
}
.other_img-get {
  width: 100%;
  position: relative;
  height: 320px;
}
.btn-re-more {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  width: 170px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  background: #de5d0a;
  border: 1px solid #de5d0a;
  border-bottom-right-radius: 11px;
}
.other_img img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.other_img-get img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.other_img-get .board_directors {
  font-weight: 600;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 8%;
  color: #fff;
}
.other_img-get .text-directors {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 65%;
  left: 8%;
}
.text-vet-milestone {
  margin-top: 10px;
}
.text-vet-milestone .vet-mil {
  font-weight: 600;
  font-size: 20px;
  color: #1d1d1d;
}
.text-vet-milestone .smail-found {
  font-weight: 400;
  font-size: 14px;
  color: #424245;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(1.3em * 3);
  line-height: 1.3em;
}
.get-to-know-us p {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  color: #1d1d1d;
}
.get-to-know-us span {
  color: #de5d0a;
}
@media (width < 950px) {
  .profile-company {
    margin-top: 140px;
  }
}
@media (width < 450px) {
  .company-profile span {
    font-size: 24px;
  }
  .vet-background .vet {
    font-size: 20px;
  }
}
</style>